
.testimonial-contr[data-v-21fb76f1] {
    padding-bottom: 50px;
}
.testimonial-contr .testimonial[data-v-21fb76f1] {
    margin-top: 20px;
}
.testimonial-contr .testimonial img[data-v-21fb76f1] {
    width: auto !important;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 100%;
    max-width: 180px;
}
.testimonial-contr .testimonial h3[data-v-21fb76f1] {
    color: #213B4D;
    font-size: 24px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.testimonial-contr .testimonial p[data-v-21fb76f1] {
    color: #A4A2A2;
    height: 24px;
}
.testimonial-contr .testimonial .testi-content[data-v-21fb76f1] {
    background: #F1F3F5;
    border-radius: 10px;
    color: #213B4D;
    padding: 15px;
    text-align: left;
}
.tl-contr h1[data-v-21fb76f1] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-21fb76f1]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-21fb76f1] {
    padding-top: 50px;
}
.to-top h1[data-v-21fb76f1]::before {
    margin: auto;
    left: 0;
    right: 0;
}
.testimonial[data-v-21fb76f1]{
    margin: 0px -30px;
}
@media all and (max-width: 1440px) {
.to-top[data-v-21fb76f1] {
        padding-top: 70px;
}
.testimonial-contr[data-v-21fb76f1] {
        padding-bottom: 70px;
}
.testimonial-contr .testimonial[data-v-21fb76f1] {
        margin-top: 30px;
}
}
@media all and (max-width: 991px) {
.testimonial-contr .testimonial img[data-v-21fb76f1]{
        max-width: 150px;
}
.testimonial-contr .testimonial[data-v-21fb76f1] {
        margin-top: 20px;
}
}
@media all and (max-width: 599px) {
.to-top[data-v-21fb76f1] {
        padding-top: 40px;
}
.testimonial-contr[data-v-21fb76f1] {
        padding-bottom: 40px;
}
.testimonial-contr .testimonial img[data-v-21fb76f1] {
        max-width: 120px;
}
}