
.treatment[data-v-d5d7eecf] {
    padding-top: 50px;
    background-color: #F1F3F5;
}
.treatment .treatment-list[data-v-d5d7eecf] {
    padding-left: 60px;
    text-align: left;
    min-height: 120px;
    padding-bottom: 50px;
}
.treatment .treatment-list img[data-v-d5d7eecf] {
    position: absolute;
    left: 0;
    object-fit: cover;
    width: 40px;
    height: 40px;
}
.tl-contr h1[data-v-d5d7eecf] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-d5d7eecf]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-d5d7eecf] {
    padding-top: 100px;
}
.to-top h1[data-v-d5d7eecf]::before {
    margin: auto;
    left: 0;
    right: 0;
}
@media all and (max-width: 1440px) {
.treatment[data-v-d5d7eecf] {
        padding-top: 70px;
        padding-bottom: 20px;
}
}
@media all and (max-width: 991px) {
.treatment .treatment-list[data-v-d5d7eecf]{
        padding-left: 70px;
}
.treatment[data-v-d5d7eecf]{
        padding-top: 40px;
        padding-bottom: 0px;
}
.treatment .treatment-list img[data-v-d5d7eecf] {
        width: 50px;
        height: auto;
}
}
@media all and (max-width: 767px) {
.list-item[data-v-d5d7eecf]{
        width: 50%;
}
}
@media all and (max-width: 480px) {
.list-item[data-v-d5d7eecf]{
        width: 100%;
}
.treatment .treatment-list[data-v-d5d7eecf]{
        min-height: 75px;
        padding-bottom: 0;
        padding-left: 0;
        text-align: center;
        margin-bottom: 30px;
}
.treatment .treatment-list img[data-v-d5d7eecf]{
        position: static;
        position: initial;
}
.treatment-list h2[data-v-d5d7eecf] {
        margin: 15px 0px;
}
}