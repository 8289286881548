.about-section[data-v-983ecf41]{
    overflow-x: visible;
    overflow-x: initial;
}
.about-section .about-content[data-v-983ecf41] {
    background: linear-gradient(162.38deg,  rgba(255, 255, 255, 0.5) 4.19%,  rgba(255, 255, 255, 0.49) 30.14%,  rgba(255, 255, 255, 0.9) 57.79%);
    position: absolute;
    top: 50px;
    left: 400px;
    width: 650px;
    bottom: 0;
    padding: 30px 30px;
}
.about-section .about-content h1[data-v-983ecf41] {
    margin-bottom: 10px;
    position: relative;
    padding-top: 15px;
}
.about-section .about-content h1[data-v-983ecf41]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.about-section .about-content #simple-bar[data-v-983ecf41] {
    max-height: 90%;
    padding-right: 20px;
}
.about-section .about-content #simple-bar .simplebar-track[data-v-983ecf41] {
    background: #e5e3de;
    border-radius: 15px;
    right: auto;
    left: -50px;
}
.about-section .about-content #simple-bar .simplebar-track .simplebar-scrollbar[data-v-983ecf41] {
    background: #e6771b;
}
.about-section .about-content #simple-bar .simplebar-track .simplebar-scrollbar[data-v-983ecf41]::before {
    display: none;
}
.about-section .about-content #simple-bar .simplebar-track.simplebar-vertical[data-v-983ecf41] {
    width: 4px;
}
.about-section .teachable-contr .feature h2[data-v-983ecf41] {
    line-height: 90px;
    min-width: 400px !important;
    min-height: 90px;
    background: #E6771B;
    color: #fff;
    margin: 0;
    text-align: right;
    padding-right: 20px;
}
.about-section .teachable-contr .caro-contr[data-v-983ecf41] {
    background: #F1F3F5;
    overflow: hidden;
    width: 100%;
    padding-right: 80px;
}
.about-section .teachable-contr .caro-contr .owl-item[data-v-983ecf41] {
    width: auto !important;
}
.about-section .teachable-contr .caro-contr .item[data-v-983ecf41] {
    padding-top: 15px;
}
.about-section .teachable-contr .caro-contr .item p img[data-v-983ecf41]{
    max-width: 130px;
}
.caro-contr img[data-v-983ecf41] {
    max-width: 150px;
}
.abt-img img[data-v-983ecf41] {
    height: 400px;
    width: 100%;
    object-fit: cover;
}
@media all and (max-width: 1600px) {
.about-section .about-content[data-v-983ecf41]{
        left: 240px;
}
.about-section .teachable-contr .feature h2[data-v-983ecf41] {
        min-width: 240px !important;
}
}
@media all and (max-width: 1366px) {
.about-section .teachable-contr .feature h2[data-v-983ecf41]{
        min-width: 190px !important;
}
.about-section .about-content[data-v-983ecf41]{
        left: 190px;
        width: 500px;
}
}
@media all and (max-width: 991px) {
.about-section .about-content[data-v-983ecf41] {
        width: 400px;
        padding: 30px 30px;
}
}
@media all and (max-width: 767px) {
.about-section .about-content[data-v-983ecf41] {
        width: 80%;
        left: 0;
}
.about-section .teachable-contr .feature h2[data-v-983ecf41] {
        min-width: 150px !important;
}
.about-section .teachable-contr .caro-contr[data-v-983ecf41] {
        padding-right: 0px;
}
.teachable-contr[data-v-983ecf41] {
        display: inline !important;
        display: initial !important;
}
.about-section .teachable-contr .feature h2[data-v-983ecf41] {
        line-height: 40px;
        min-height: 40px;
        text-align: left;
        padding-left: 20px;
}
}