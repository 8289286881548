.tl-contr[data-v-a35e1a09] {
    display: inline-block;
}
.tl-contr h1[data-v-a35e1a09] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-a35e1a09]::before {
    content: "";
    background: #e6771b;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.tl-contr h2.ssd[data-v-a35e1a09] {
    position: relative;
    padding-top: 15px;
    margin-bottom: 60px;
    font-size: 38px;
}
.tl-contr h2.ssd[data-v-a35e1a09]::before {
    content: "";
    background: #e6771b;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.tl-contr label[data-v-a35e1a09] {
    padding-top: 6px;
}
.tl-contr ul.country-contr[data-v-a35e1a09] {
    float: right;
    margin-bottom: 5px;
}
.tl-contr ul.country-contr li[data-v-a35e1a09] {
    float: left;
    border-bottom: 6px solid #f1f3f5;
    padding-left: 20px;
}
.tl-contr ul.country-contr li select[data-v-a35e1a09] {
    border: 1px solid #e6771b;
    border-radius: 10px;
    padding: 5px 15px;
}
.treatment-section[data-v-a35e1a09] {
    background: #e5e5e5;
    padding: 50px 0px;
    padding-bottom: 0px;
}
.treatment-section .t-list[data-v-a35e1a09] {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 25px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}
.treatment-section .t-list h2[data-v-a35e1a09] {
    margin-bottom: 0px;
    margin-top: 10px;
    height: 30px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.treatment-section .t-list .img-rd img[data-v-a35e1a09] {
    border-radius: 5px 5px 140px 140px;
}
.treatment-section .t-list .icon-mn[data-v-a35e1a09] {
    width: 155px;
    background: #fff;
    height: 155px;
    margin: auto;
    position: relative;
    border-radius: 100%;
    border: 5px solid #ffffff;
    margin-top: -80px;
}
.treatment-section .t-list .icon[data-v-a35e1a09] {
    margin: auto;
    display: table;
    border: 8px solid #e6771b;
    width: 146px;
    height: 146px;
    border-radius: 100%;
}
.icon img[data-v-a35e1a09] {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.treatment-section .t-list ul[data-v-a35e1a09] {
    height: 100px;
    overflow: hidden;
    color: #213B4D;
    margin: 0;
}
.treatment-section .t-list ul li[data-v-a35e1a09] {
    line-height: 32px;
}
.treatment-cn[data-v-a35e1a09]{
    margin: 0px -52px;
    display: contents;
}
@media all and (max-width: 1600px) {
}
@media all and (max-width: 1440px) {
.treatment-section .t-list .img-rd img[data-v-a35e1a09] {
        border-radius: 5px 5px 120px 120px;
}
h2[data-v-a35e1a09]{
        font-size: 24px;
}
}
@media all and (max-width: 1199px) {
.treatment-section .t-list .icon-mn[data-v-a35e1a09] {
        width: 110px;
        height: 110px;
}
.treatment-section .t-list .icon[data-v-a35e1a09] {
        border: 4px solid #e6771b;
        width: 100px;
        height: 100px;
}
.icon img[data-v-a35e1a09] {
        width: 50px;
        height: auto;
}
}
@media all and (max-width: 991px) {
.sj-full[data-v-a35e1a09]{
        width: 50%;
}
.treatment-section[data-v-a35e1a09] {
        padding-top: 50px;
}
h2[data-v-a35e1a09]{
        font-size: 21px;
}
}
@media all and (max-width: 480px) {
.sj-full[data-v-a35e1a09]{
        width: 100%;
}
}