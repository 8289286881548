
.blog-section .blog-list[data-v-febb4164] {
    margin-top: 60px;
}
.blog-section .blog-list h2[data-v-febb4164] {
    height: 25px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 12px;
    font-family: WorkSans-Medium;
    font-weight: 600;
}
.blog-detail-contr[data-v-febb4164]{
    margin-top: 80px;
}
.adds-contr[data-v-febb4164] {
    margin-top: 50px;
}
.recent-blog h2[data-v-febb4164] {
    margin-bottom: 25px;
}
.recent-blog .blog-list[data-v-febb4164] {
    position: relative;
    padding-left: 88px;
    min-height: 70px;
    margin-top: 0;
}
.recent-blog .blog-list img[data-v-febb4164] {
    max-width: 68px;
    position: absolute;
    left: 0;
}
.recent-blog .blog-list h2[data-v-febb4164] {
    font-size: 18px;
    margin-bottom: 0px;
}
.recent-blog .blog-list .content[data-v-febb4164] {
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.packages .item-list .content svg[data-v-febb4164] {
    margin-right: 5px;
}
@media all and (max-width: 1600px) {
.main-pd[data-v-febb4164] {
        padding: 0px 100px;
}
}




/*second section open*/
.tl-contr[data-v-febb4164] {
    margin-bottom: 15px;
    display: inline-block;
    margin-top: 50px;
}
.tl-contr h1[data-v-febb4164] {
    margin-bottom: 20px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-febb4164]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.ml-top[data-v-febb4164]{
    margin-top: 90px;
}
.tl-contr h2[data-v-febb4164] {
    font-size: 18px;
    margin-top: 20px;
}
.tl-contr label[data-v-febb4164] {
    padding-top: 6px;
}
.tl-contr ul.country-contr[data-v-febb4164] {
    float: right;
    margin-bottom: 5px;
}
.tl-contr ul.country-contr li[data-v-febb4164] {
    float: left;
    padding-left: 20px;
}
.tl-contr ul.country-contr li select[data-v-febb4164] {
    border: 1px solid #E6771B;
    border-radius: 10px;
    padding: 5px 15px;
}
.packages .item-list[data-v-febb4164] {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative;
}
.packages .item-list .image[data-v-febb4164] {
    display: block;
    width: 100%;
    height: auto;
}
.packages .item-list .overlay[data-v-febb4164] {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}
.packages .item-list .hs-div:hover .overlay[data-v-febb4164] {
    bottom: 0;
    height: 100%}
.packages .item-list .text[data-v-febb4164] {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,  -50%);
    transform: translate(-50%,  -50%);
    text-align: center;
    color: #E6771B;
    width: 100%;
    padding: 20px;
}
.packages .item-list .text a[data-v-febb4164] {
    color: #213B4D;
    font-family: TitilliumWeb-SemiBold;
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
}
.packages .item-list img[data-v-febb4164] {
    width: 100%;
    border-radius: 10px;
}
.packages .item-list h2[data-v-febb4164] {
    text-align: left;
    padding: 20px;
    margin-bottom: 0;
}
.packages .item-list .content[data-v-febb4164] {
    padding: 20px;
    padding-top: 0;
}
.book-now[data-v-febb4164] {
    height: 45px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 220px;
    font-size: 18px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .text ul[data-v-febb4164] {
    padding: 30px 0px;
    text-align: left;
    margin: auto;
    display: table;
}
.packages .item-list .text ul li[data-v-febb4164]{
    position: relative;
    line-height: 34px;
    padding-left: 35px;
}
.packages .item-list .text ul li[data-v-febb4164]::before {
    background-image: url(../../images/list-tick-icon.svg);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 20px;
    height: 20px;
    background-size: 20px;
}
/*second section open*/
@media all and (max-width: 1600px) {
.main-pd[data-v-febb4164] {
    padding: 0px 100px;
}
}



