/*form section open*/
.form-contr[data-v-231e278a] {
    background: #EDF2F7;
    color: #fff;
    padding: 20px 30px;
    width: 390px;
    float: right;
    border-radius: 10px;
    background: #EDF2F7;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.07);
    margin-top: 50px;
    margin-right: 70px;
}
.form-contr .form[data-v-231e278a] {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 0px 15px;
    background: #fff;
    color: #213B4D;
    position: relative;
    font-size: 14px;
}
.form-contr label[data-v-231e278a] {
    color: #213B4D;
    font-size: 16px;
    margin-bottom: 5px;
}
.form-contr .mnt[data-v-231e278a] {
    margin-top: 15px;
}
.form-contr textarea[data-v-231e278a] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px;
}
.form-contr .inputfile[data-v-231e278a] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.form-contr span[data-v-231e278a] {
    line-height: 50px;
}
.form-contr .inputfile+label[data-v-231e278a] {
    cursor: pointer;
}
.form-contr .no-js .inputfile+label[data-v-231e278a] {
    display: none;
}
.form-contr .inputfile:focus+label[data-v-231e278a], .form-contr .inputfile.has-focus+label[data-v-231e278a] {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.form-contr .inputfile+label svg[data-v-231e278a] {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    position: absolute;
    right: 15px;
    top: 15px;
}
.errmsg[data-v-231e278a]{
    color: red;
    padding: 5px;
    font-size:medium;
    margin-bottom: 5px;
}
.submit1[data-v-231e278a]{
    background-color: #E6771B !important;
    color: #fff !important;
}
.bn-content[data-v-231e278a] {
    color: #fff;
    padding-top: 80px;
    padding-left: 70px;
}
.forms-cn[data-v-231e278a] {
    margin-top: -400px;
}
.email-label[data-v-231e278a]{
    margin-top: 15px;
}
img.fm-bg[data-v-231e278a] {
    margin-top: 50px;
    height: 400px;
    width: 100%;
    object-fit: cover;
}
/*form section close*/









/*second section open*/
.show-list[data-v-231e278a] {
    text-align: center;
    min-height: 240px;
    margin-top: 50px;
    position: relative;
    transition: 0.3s;
    color: #fff;
}
.img-icon img[data-v-231e278a] {
    border-radius: 5px;
    height: 280px;
    object-fit: cover;
}
.show-list h2[data-v-231e278a] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 15px 10px;
    background: #ffffff47;
    border-radius: 10px;
    left: 0;
    right: 0;
    width: 80%;
    margin: auto;
}
/*second section close*/


/*Protect your staff’s*/
.protect-cn[data-v-231e278a] {
    background: linear-gradient(269.33deg, #EDF2F7 19.04%, #ECEDF2 77.24%);
    border-radius: 20px;
    margin-top: 50px !important;
}
.content-cn h1[data-v-231e278a] {
    color: #E6771B;
    padding-top: 35px;
}
.content-cn[data-v-231e278a] {
    padding: 0px 30px 0px 50px;
}
/*Protect your staff’s*/



/*benefit open*/
.tl-contr[data-v-231e278a] {
    margin-bottom: 15px;
    display: inline-block;
    margin-top: 50px;
}
.tl-contr h1[data-v-231e278a] {
    margin-bottom: 20px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-231e278a]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin: auto;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    right: 0;
}
.cvbn[data-v-231e278a]{
    margin: auto;
    display: table;
}
.im-contr[data-v-231e278a]{
    width: 1254px;
    margin: auto;
}
.im1[data-v-231e278a]{
    position: relative;
    left: 16px;
    display: inline-block;
}
.im2[data-v-231e278a]{
    position: relative;
    left: 3px;
    top: -1px;
    display: inline-block;
}
.im3[data-v-231e278a]{
    position: relative;
    left: 20px;
    top: -4px;
    display: inline-block;
}
.im4[data-v-231e278a]{
    left: 200px;
    position: relative;
    top: -121px;
    display: inline-block;
}
.im5[data-v-231e278a]{
    left: 216px;
    position: relative;
    top: -119px;
    display: inline-block;
}
/*benefit close*/


/*how it works open*/
.work-contr[data-v-231e278a]{
    background-color: #EDF2F7;
    padding-bottom: 50px;
}
.works h2[data-v-231e278a] {
    background: #058ED2;
    color: #fff;
    text-align: center;
    padding: 10px 0px;
    border-radius: 5px;
    margin-bottom: 0;
}
.works h1[data-v-231e278a] {
    background: #058ED2;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    font-size: 24px;
    padding-top: 10px;
}
.works p[data-v-231e278a] {
    background: #fff;
    border-radius: 0px 0px 4px 4px;
    padding: 20px;
    text-align: center;
}
a.tie-up[data-v-231e278a] {
    background: #E6771B;
    color: #fff;
    height: 45px;
    border-radius: 50px;
    padding-top: 12px;
    width: 250px;
    display: table;
    margin: auto;
    text-align: center;
    margin-top: 20px;
}
.works[data-v-231e278a] {
    max-width: 350px;
    margin: auto;
    position: relative;
    margin-top: 10px;
}
img.ns-arrow1[data-v-231e278a] {
    position: absolute;
    left: -40px;
    top: 135px;
}
img.ns-arrow2[data-v-231e278a] {
    position: absolute;
    right: -40px;
    top: 135px;
}

/*how it works close*/


/*Meet our Partners open*/
.top-brand[data-v-231e278a] {
    background-color: #058ED2;
    border-radius: 10px;
    padding: 30px 30px;
}
.top-brand img[data-v-231e278a] {
    width: auto;
    border-radius: 10px;
    max-width: 100%}
.top-brand .owl-nav .owl-prev[data-v-231e278a] {
    left: 25px;
}
.top-brand .owl-nav .owl-prev span[data-v-231e278a] {
    background-image: url(../../images/caro-pre-icon-w.svg);
}
.top-brand .owl-nav .owl-next[data-v-231e278a] {
    right: 25px;
}
.top-brand .owl-nav .owl-next span[data-v-231e278a] {
    background-image: url(../../images/caro-nxt-icon-w.svg);
}
.best-package[data-v-231e278a] {
    border-radius: 15px;
    margin-top: 90px;
    overflow: hidden;
}
/*Meet our Partners close*/


/*partners say open*/
.say-list[data-v-231e278a] {
    padding: 0px 30px;
}
.partners-say[data-v-231e278a] {
    background: #F1F3F5;
    padding: 50px 10px;
}
.say-list h3[data-v-231e278a]{
    margin: 10px 0px;
}
.say-list span[data-v-231e278a] {
    color: #E6771B;
    padding-bottom: 10px;
    display: block;
}
.tl-contr.cl1 h1[data-v-231e278a]::before{
    margin: 0;
    margin: initial;
}
/*partners say close*/
.im-contr h1[data-v-231e278a]{
    display: none;
}
@media all and (max-width: 1440px) {
.show-list[data-v-231e278a]{
        margin-top: 50px;
}
}
@media all and (max-width: 1280px) {
img.ns-arrow1[data-v-231e278a]{
        display: none;
}
img.ns-arrow2[data-v-231e278a]{
        display: none;
}
}
@media all and (max-width: 1199px) {
.im-contr h1[data-v-231e278a]{
        display: block;
        font-size: 18px;
}
.im-contr img[data-v-231e278a]{
        display: none;
}
.im-contr[data-v-231e278a]{
        width: auto;
}
.im1[data-v-231e278a]{
        display: inline;
        display: initial;
        position: static;
        position: initial;
}
.im2[data-v-231e278a]{
        display: inline;
        display: initial;
        position: static;
        position: initial;
}
.im3[data-v-231e278a]{
        display: inline;
        display: initial;
        position: static;
        position: initial;
}
.im4[data-v-231e278a]{
        display: inline;
        display: initial;
        position: static;
        position: initial;
}
.im5[data-v-231e278a]{
        display: inline;
        display: initial;
        position: static;
        position: initial;
}
.im-contr div[data-v-231e278a] {
        float: left;
        background: #edf2f7;
        margin-bottom: 20px;
        padding: 5px 15px;
        margin-left: 1.4%;
        width: 48%;
}
}
@media all and (max-width: 991px) {
.form-contr[data-v-231e278a] {
        width: 100%;
        margin-right: 15px;
}
.bn-content[data-v-231e278a]{
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 30px;
}
}
@media all and (max-width: 767px) {
.im-contr div[data-v-231e278a]{
        margin-left: 0;
        width: 100%;
}
.im-contr[data-v-231e278a]{
        width: auto;
        padding: 0px 25px;
}
.forms-cn .col-6[data-v-231e278a]{
        width: 100%;
}
.form-contr[data-v-231e278a] {
        margin: 0;
}
.form-main[data-v-231e278a]{
        padding: 0px 20px;
}
.bn-content[data-v-231e278a] {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
}
.fmbg-main[data-v-231e278a] {
        margin-top: 30px;
}
.form-contr .form[data-v-231e278a]{
        height: 40px;
}
.protect-cn .col-5[data-v-231e278a] {
        display: none;
}
.content-cn[data-v-231e278a] {
        padding: 0px 20px 0px 20px;
        width: 100%;
}
.content-cn h1[data-v-231e278a] {
        padding-top: 15px;
}
.list-main-show .col-4[data-v-231e278a] {
        width: 100%;
}
.img-icon img[data-v-231e278a]{
        height: 230px;
        width: 100%;
}
.show-list[data-v-231e278a]{
        margin-top: 10px;
}
.list-main-show[data-v-231e278a] {
        margin-top: 15px;
}
.protect-cn[data-v-231e278a]{
        margin-top: 20px !important;
}
.work-contr .col-4[data-v-231e278a] {
        width: 100%;
}
.cvbn[data-v-231e278a] {
        padding: 0px 10px;
        text-align: center;
}
.top-brand[data-v-231e278a] {
        padding: 10px 10px;
}
}