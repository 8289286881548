
.hospital[data-v-743a0f33] {
    width: 100%;
    position: absolute;
    bottom: -35px;
}
.hospital .hlogo img[data-v-743a0f33] {
    width: 25px !important;
    margin-bottom: 10px;
    margin-left: 15px;
}
.hospital[data-v-743a0f33]::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 84px;
    background: #E6771B;
    left: 0;
    bottom: 0;
}
.shade img.shade-frnt[data-v-743a0f33] {
    position: absolute;
    bottom: 100px;
    top: auto;
    z-index: 1;
    width: 50% !important;
    max-width: 100%;
    left: -4px;
}
.shade img.shade-back[data-v-743a0f33] {
    position: absolute;
    bottom: 100px;
    left: 0;
    width: auto !important;
    max-width: 100%
}
.plml-botm[data-v-743a0f33]{
    margin-bottom: 35px;
}
.shade-bg[data-v-743a0f33]{
    background: #BCBCBC;
    -webkit-clip-path: polygon(0 30%, 100% 0, 100% 75%, 0 100%);
    clip-path: polygon(0 30%, 100% 0, 100% 75%, 0 100%);
    position: absolute;
    width: 100%;
    bottom: 55px;
    height: 350px;
}
.shade-fn[data-v-743a0f33] {
    background: rgba(196, 196, 196, 0.4);
    -webkit-clip-path: polygon(0 20%, 100% 5%, 100% 82%, 0 93%);
    clip-path: polygon(0 20%, 100% 5%, 100% 82%, 0 93%);
    position: absolute;
    width: 50%;
    bottom: 30px;
    height: 380px;
    z-index: 1;
}
.hs-content[data-v-743a0f33] {
    height: 48px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    padding-left: 15px;
}
.botm-content[data-v-743a0f33] {
    position: relative;
    text-align: right;
    float: right;
    bottom: 66px;
    z-index: 1;
}
.botm-content .book-now[data-v-743a0f33] {
    width: 132px;
    height: 40px;
    background: #E6771B;
    color: #fff;
    border: none;
    position: absolute;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    right: -46px;
    bottom: 167px;
}
.botm-content .book-now a[data-v-743a0f33]{
    color: #fff;
}
.botm-content .ds-cn[data-v-743a0f33] {
    background: #058ED2;
    color: #fff;
    padding: 5px 15px;
}
.botm-content .ds-cn p[data-v-743a0f33] {
    font-size: 16px;
    margin-bottom: 0;
}
.ds-img img[data-v-743a0f33] {
    float: right;
    object-fit: cover;
}
.tl-contr h1[data-v-743a0f33] {
    margin-bottom: 40px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-743a0f33]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-743a0f33] {
    padding-top: 50px;
}
.to-top h1[data-v-743a0f33]::before {
    margin: auto;
    left: 0;
    right: 0;
}
.testimonial[data-v-743a0f33]{
    margin: 0px -30px;
}
img.hs-logo-im[data-v-743a0f33] {
    width: 80px;
    position: absolute;
    bottom: 5px;
    right: 5px;
}
img.hlogo[data-v-743a0f33] {
    margin-left: 10px;
}
@media all and (max-width: 1440px) {
.to-top[data-v-743a0f33] {
        padding-top: 70px;
}
.tl-contr h1[data-v-743a0f33] {
        margin-bottom: 50px;
}
}
@media all and (max-width: 991px) {
.to-top[data-v-743a0f33]{
        padding-top: 50px;
}
}

