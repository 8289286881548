
.news-section .ns-contr[data-v-e77629a3] {
    background: #F1F3F5;
    padding-top: 50px;
    padding-bottom: 30px;
}
.news-section .ns-contr .mbb[data-v-e77629a3] {
    margin-bottom: 20px;
}
.news-section .ns-contr img[data-v-e77629a3] {
    width: 100%;
    height: 225px;
    object-fit: cover;
}
.news-section .ns-contr .news-content[data-v-e77629a3] {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 60%;
    padding: 20px 20px;
}
.news-section .ns-contr .news-content[data-v-e77629a3]::before {
    background: #E6771B;
    content: "";
    width: 2px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
}
.news-section .ns-contr .news-content h1[data-v-e77629a3] {
    height: 55px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 21px;
}
.news-section .ns-contr .news-content p[data-v-e77629a3] {
    font-size: 16px;
    margin-bottom: 0;
}
.news-section .ns-contr .news-first img[data-v-e77629a3] {
    height: 470px;
}
.tl-contr h1[data-v-e77629a3] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-e77629a3]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-e77629a3] {
    padding-top: 50px;
}
.to-top h1[data-v-e77629a3]::before {
    margin: auto;
    left: 0;
    right: 0;
}
@media all and (max-width: 1440px) {
.to-top[data-v-e77629a3] {
        padding-top: 70px;
}
.news-section .ns-contr[data-v-e77629a3] {
        padding-top: 70px;
        padding-bottom: 50px;
}
}
@media all and (max-width: 599px) {
.news-section .ns-contr[data-v-e77629a3] {
        padding-top: 40px;
        padding-bottom: 20px;
}
.news-section .ns-contr .mbb[data-v-e77629a3] {
        width: 100%;
}
.news-sec[data-v-e77629a3]{
        width: 100%;
}
.news-section .ns-contr .news-first img[data-v-e77629a3]{
        height: 250px;
}
.news-section .ns-contr .news-content[data-v-e77629a3]{
        width: 100%;
        position: static;
        position: initial;
}
.to-top[data-v-e77629a3] {
        padding-top: 50px;
}
}
