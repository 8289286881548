.banner-bg[data-v-1bf462d7] {
    height: 531px;
    object-fit: cover;
}
.contact-bnr[data-v-1bf462d7]{
    height: 220px;
    width: 100%;
}
.banner-bottom h1[data-v-1bf462d7] {
    background: #E6771B;
    box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    color: #fff;
    max-width: 630px;
    padding: 10px 90px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    position: relative;
    font-size: 32px;
}
.banner-bottom h1 img[data-v-1bf462d7] {
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.banner-bottom h1 .arrow-right[data-v-1bf462d7] {
    right: 20px;
    left: auto;
}
.main-banner .banner-content[data-v-1bf462d7] {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    max-width: 1380px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.banner-content.plc[data-v-1bf462d7]{
    padding: 0px 15px;
}
@media all and (max-width: 991px) {
.contact-bnr[data-v-1bf462d7]{
        height: 300px;
}
}