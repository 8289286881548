.treatment-section[data-v-3c547a86] {
    position: relative;
    z-index: 1;
    background: #fff;
}
.Excellence[data-v-3c547a86] {
    background: #F1F3F5;
    position: relative;
    padding-left: 220px;
    min-height: 300px;
    padding-top: 25px;
    margin-top: 50px;
    text-align: left;
    color: #213B4D;
}
.Excellence img[data-v-3c547a86] {
    position: absolute;
    left: 25px;
    top: 25px;
    border: 20px solid #fff;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    object-fit: cover;
}
.Excellence h2[data-v-3c547a86] {
    padding-bottom: 15px;
}
.Excellence ul li[data-v-3c547a86] {
    position: relative;
    line-height: 32px;
    padding-left: 25px;
    width: 50%;
    float: left;
}
.Excellence ul li[data-v-3c547a86]::before {
    width: 14px;
    height: 14px;
    content: "";
    background: #C4C4C4;
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 8px;
}
.right-sec[data-v-3c547a86] {
    padding-left: 60px;
}
.right-sec .form-contr[data-v-3c547a86] {
    background: #EDF2F7;
    color: #213B4D;
    margin-top: 50px;
    padding: 40px 30px; 
    padding-bottom: 20px;
}
.form-contr h2[data-v-3c547a86] {
    text-align: left;
    margin-bottom: 25px;
}
.right-sec label[data-v-3c547a86] {
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
}
.right-sec .form-contr .form[data-v-3c547a86] {
    height: 50px !important;
    width: 100% !important;
    border-radius: 5px !important;
    border: none !important;
    margin-bottom: 20px !important;
    padding: 0px 15px;
    background: #fff !important;
    color: #213B4D !important;
    position: relative !important;
    padding-left: 15px !important;
    top: 0 !important;
    font-size: 18px !important;
}
.right-sec .form-contr .mnt[data-v-3c547a86] {
    margin-top: 15px;
}
.right-sec .form-contr textarea[data-v-3c547a86] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    padding: 10px 15px;
}
.right-sec .recent-blog[data-v-3c547a86] {
    background: #EDF2F7;
    border-radius: 10px;
    padding: 40px 30px;
    margin-top: 30px;
    color: #213B4D;
}
.right-sec .recent-blog h2[data-v-3c547a86] {
    margin-bottom: 25px;
}
.right-sec .recent-blog .blog-list[data-v-3c547a86] {
    position: relative;
    padding-left: 88px;
    min-height: 90px;
    text-align: left;
    color: #213B4D;
}
.right-sec .recent-blog .blog-list img[data-v-3c547a86] {
    max-width: 68px;
    position: absolute;
    left: 0;
    top: 0;
}
.right-sec .recent-blog .blog-list h2[data-v-3c547a86] {
    font-size: 18px;
    margin-bottom: 8px;
}
.right-sec .recent-blog .blog-list .content[data-v-3c547a86] {
    font-size: 14px;
    line-height: 20px;
}
/*treatment section close*/
