.doctors[data-v-f5c7f241] {
    background: #F1F3F5;
    padding: 20px;
    padding-top: 50px;
    border-radius: 10px;
    margin-top: 10px !important;
    position: relative;
    z-index: 1;
}
.doctors-list[data-v-f5c7f241] {
    position: relative;
    padding-left: 115px;
    min-height: 170px;
    margin-bottom: 25px;
    border-bottom: 1px solid #333333;
}
.doctor-img[data-v-f5c7f241] {
    position: absolute;
    left: 0px;
    width: 90px;
}
.doctor-img img[data-v-f5c7f241] {
    max-width: 100%;
    position: static !important;
    position: initial !important;
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 100%;
    background-color: #fff;
}
.btcn[data-v-f5c7f241] {
    background: #00AAC4;
    border-radius: 5px;
    color: #fff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btcn1[data-v-f5c7f241] {
    background: #E6771B;
    border-radius: 5px;
    color: #ffffff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 15px;
}
.btcn[data-v-f5c7f241]:hover {
    background-color: #07899d;
    color: #fff;
}
.btcn1[data-v-f5c7f241]:hover {
    background-color: #cf660f;
    color: #ffffff;
}
.tip-list img[data-v-f5c7f241] {
    position: static !important;
    position: initial !important;
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.tip-list[data-v-f5c7f241] {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}
.tip-list p[data-v-f5c7f241] {
    font-size: 18px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
    color: #213B4D;
}
.t1[data-v-f5c7f241] {
    color: #E6771B;
    font-size: 14px;
    float: left;
}
.tip-tl[data-v-f5c7f241] {
    color: #058ED2;
    text-align: left;
    margin-bottom: 15px;
}
.doctor-left[data-v-f5c7f241] {
    padding-right: 50px;
    padding-left: 20px;
    border-right: 1px solid #C4C4C4;
    width: 73%;
}
.doctor-right[data-v-f5c7f241] {
    padding-left: 50px;
    width: 27%;
}
.doctors-list[data-v-f5c7f241]:last-child {
    border: none;
}
.ds-detail[data-v-f5c7f241] {
    float: left;
    text-align: left;
    color: #213B4D;
}