.contct-contr .left-sec[data-v-3a3d88cd] {
    padding: 50px 100px 40px 260px;
}
.contct-contr .left-sec .en-contr[data-v-3a3d88cd] {
    border: 1px solid #BCBEC0;
    border-radius: 10px;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 20px
}
.contct-contr .left-sec .en-contr h3[data-v-3a3d88cd] {
    color: #E6771B;
    font-size: 18px;
    font-family: TitilliumWeb-SemiBold;
}
.contct-contr .left-sec .en-contr h2[data-v-3a3d88cd] {
    font-family: TitilliumWeb-Regular
}
.contct-contr .left-sec select[data-v-3a3d88cd] {
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    height: 45px;
    padding: 0px 10px;
    margin-top: 15px
}
.contct-contr .left-sec .map-contr[data-v-3a3d88cd] {
    margin-top: 50px;
    border-left: 5px solid #e6771b;
    padding-left: 25px;
    min-height: 120px;
    position: relative
}
.contct-contr .left-sec .map-contr .map-content[data-v-3a3d88cd] {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}
.contct-contr .left-sec .map-contr .map-content p[data-v-3a3d88cd] {
    position: relative;
    padding-left: 40px
}
.contct-contr .left-sec .map-contr .map-content p img[data-v-3a3d88cd] {
    left: 0;
    position: absolute
}
.contct-contr .left-sec .map-contr .map-content .map[data-v-3a3d88cd] {
    color: #00B3FF;
    float: right
}
.contct-contr .right-sec[data-v-3a3d88cd] {
    padding: 50px 260px 50px 150px;
    background: #F1F3F5
}
.contct-contr .right-sec .form-contr[data-v-3a3d88cd] {
    background: #E6771B;
    color: #fff;
    padding: 30px 30px
}
.contct-contr .right-sec .form-contr .form[data-v-3a3d88cd] {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 0px 15px;
    background: #fff;
    color: #213B4D;
    position: relative
}
.contct-contr .right-sec .form-contr .mnt[data-v-3a3d88cd] {
    margin-top: 15px
}
.contct-contr .right-sec .form-contr textarea[data-v-3a3d88cd] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px
}
.contct-contr .right-sec .form-contr .inputfile[data-v-3a3d88cd] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}
.contct-contr .right-sec .form-contr span[data-v-3a3d88cd] {
    line-height: 50px
}
.contct-contr .right-sec .form-contr .inputfile+label[data-v-3a3d88cd] {
    cursor: pointer
}
.contct-contr .right-sec .form-contr .no-js .inputfile+label[data-v-3a3d88cd] {
    display: none
}
.contct-contr .right-sec .form-contr .inputfile:focus+label[data-v-3a3d88cd], .contct-contr .right-sec .form-contr .inputfile.has-focus+label[data-v-3a3d88cd] {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px
}
.contct-contr .right-sec .form-contr .inputfile+label svg[data-v-3a3d88cd] {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    position: absolute;
    right: 15px;
    top: 15px
}
@media all and (max-width: 1600px) {
.contct-contr .left-sec[data-v-3a3d88cd] {
        padding: 50px 50px 40px 100px
}
.contct-contr .right-sec[data-v-3a3d88cd] {
        padding: 50px 100px 50px 80px;
}
}
@media all and (max-width: 991px) {
.contct-contr .left-sec[data-v-3a3d88cd] {
        padding: 50px 20px 40px 60px;
}
.contct-contr .right-sec[data-v-3a3d88cd] {
        padding: 50px 50px 40px 20px;
}
.banner-bg[data-v-3a3d88cd]{
        height: 300px;
}
}
@media all and (max-width: 767px) {
.contct-contr .left-sec[data-v-3a3d88cd] {
        width: 100%;
        padding: 20px 50px 20px 50px;
}
.contct-contr .right-sec[data-v-3a3d88cd] {
        width: 100%;
        padding: 20px 50px 20px 50px;
}
}
@media all and (max-width: 599px) {
.contct-contr .right-sec[data-v-3a3d88cd] {
        padding: 20px 25px 20px 25px;
}
.contct-contr .left-sec[data-v-3a3d88cd] {
        width: 100%;
        padding: 20px 25px 40px 25px;
}
}