/*first image section open*/
.bn-sec-main[data-v-53d656cc] {
    margin-top: 30px;
    padding: 20px 25px;
}
.bn-sec-main img[data-v-53d656cc] {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    height: 420px;
    object-fit: cover;
}
.bn-two img[data-v-53d656cc] {
    margin:0px 0px 20px 20px;
    height: 200px;
}
.bn-one[data-v-53d656cc]{
    width: 70%;
}
.bn-two[data-v-53d656cc]{
    width: 30%;
}
/*first image section close*/



/*Aster Medcity, Ernakulam open*/
.book-ln h1[data-v-53d656cc] {
    padding-bottom: 10px;
    font-size: 24px;
}
.book-ln[data-v-53d656cc] {
    position: relative;
    padding-top: 30px !important;
}
.book-ln p[data-v-53d656cc]{
    padding-left: 35px;
}
.book-ln h2[data-v-53d656cc]{
    padding-left: 45px;
}
.book-ln img[data-v-53d656cc] {
    max-width: 25px;
    position: absolute;
    left: 0;
}
.book-im img[data-v-53d656cc] {
    border-radius: 20px;
    max-width: 95%;
}
.book-bttn[data-v-53d656cc]{
    height: 45px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    font-size: 21px;
    float: right;
    max-width: 300px;
    text-align: center;
    line-height: 43px;
    margin-bottom: 25px;
}
.book-contr[data-v-53d656cc] {
    border: 1px solid #000000;
    border-radius: 20px;
    padding: 30px;
    margin: 0;
}
.jci-nabh[data-v-53d656cc]{
    float: right;
    text-align: center;
    margin-right: 0;
}
.jci[data-v-53d656cc]{
    padding: 0;
    width: 150px;
}
.nabh[data-v-53d656cc]{
    padding: 0;
    width: 150px;
}
/*Aster Medcity, Ernakulam close*/







/*Special Features open*/
.tl-contr[data-v-53d656cc] {
    margin-bottom: 30px;
    display: inline-block;
    margin-top: 50px;
}
.tl-contr h1[data-v-53d656cc] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-53d656cc]::before {
    content: "";
    background: #e6771b;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.mn-head[data-v-53d656cc]{
    border-bottom: 1px solid #E5E5E5;
    padding: 0;
    margin-top: 50px;
}
ul.mn-head li[data-v-53d656cc] {
    float: left;
    margin-right: 30px;
    position: relative;
    padding-bottom: 10px;
}
ul.mn-head li.active[data-v-53d656cc]:before {
    width: 55px;
    height: 5px;
    background: #E6771B;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
}
ul.mn-head li.active[data-v-53d656cc]{
    color: #E6771B;
}
ul.core-contr li[data-v-53d656cc] {
    float: left;
    width: 33.333%;
    margin-bottom: 30px;
    min-height: 80px;
}
ul.core-contr li h2 img[data-v-53d656cc] {
    margin-right: 10px;
    margin-top: -5px;
    max-width: 40px;
}

/*Special Features close*/

/*Description section open*/
.cn-section[data-v-53d656cc] {
    background: #F1F3F5;
    padding: 50px;
    margin-top: 20px;
    font-size: 21px;
    line-height: 32px;
}
/*Description section close*/




/*doctors listing open*/
.doctors-listing-section[data-v-53d656cc]{
    background-color: #F1F3F5;
    margin-top: 20px !important;
    padding: 20px;
    padding-top: 45px;
}
.doctors[data-v-53d656cc]{
    background: #E5E5E5;
    padding-bottom: 80px;
    padding-top: 80px;
}
.doctors-list[data-v-53d656cc] {
    position: relative;
    padding-left: 100px;
    height: 120px;
    margin-bottom: 25px;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 10px;
}
.doctor-img[data-v-53d656cc] {
    position: absolute;
    left: 0px;
    width: 90px;
}
.links[data-v-53d656cc] {
    position: absolute;
    right: 10px;
    top: 22px;
    display: grid;
}
.doctor-img img[data-v-53d656cc]{
    max-width: 100%;
    width: 80px;
    border-radius: 100%;
    height: 80px;
    object-fit: cover;
    margin-top: 20px;
    margin-left: 10px;
}
p.star-c img[data-v-53d656cc] {
    width: 12px;
    margin-top: -4px;
}
p.star-c[data-v-53d656cc] {
    color: #E6771B;
}
.doctors-list p[data-v-53d656cc] {
    margin-bottom: 3px;
}
.doctors-list h2[data-v-53d656cc] {
    padding-top: 12px;
    font-size: 14px;
    margin-bottom: 0;
}
.doctors-list p[data-v-53d656cc] {
    font-size: 14px;
}
.btcn[data-v-53d656cc] {
    background: #F1F3F5;
    border-radius: 5px;
    color: #213B4D;
    height: 30px;
    width: 90px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    border: none;
}
ul.mn-head li[data-v-53d656cc] {
    float: left;
    margin-right: 30px;
    position: relative;
    padding-bottom: 10px;
}
ul.mn-head li.active[data-v-53d656cc]:before {
    width: 55px;
    height: 5px;
    background: #E6771B;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
}
ul.mn-head li.active[data-v-53d656cc]{
    color: #E6771B;
}
.btcn1[data-v-53d656cc] {
    background: #E6771B;
    border-radius: 5px;
    color: #ffffff;
    height: 30px;
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    line-height: 30px;
    font-size: 14px;
}
.btcn[data-v-53d656cc]:hover{
    background-color: #dcdddf;
    color: #213B4D;
}
.btcn1[data-v-53d656cc]:hover{
    background-color: #cf660f;
    color: #ffffff;
}

/*doctors listing close*/










/*Packages open*/
.tl-contr[data-v-53d656cc] {
    margin-bottom: 30px;
    display: inline-block;
}
.tl-contr h1[data-v-53d656cc] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-53d656cc]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
/* .packages {
    padding: 50px 20px;
    background: #F1F3F5;
    padding-bottom: 30px;
    margin: 0;
}
.packages .item-list {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative;
}
.packages .item-list img {
    width: 100%}
.packages .item-list h2 {
    text-align: left;
    padding: 20px;
    margin-bottom: 0;
}
.packages .item-list .benefit {
    padding: 20px;
    background: #d0eaf7;
    position: relative;
}
.packages .item-list .benefit h2 {
    padding: 0;
    padding-bottom: 15px;
}
.packages .item-list .benefit ul {
    margin: 0;
}
.packages .item-list .benefit ul li {
    text-align: left;
    position: relative;
    padding-left: 30px;
    line-height: 28px;
}
.packages .item-list .benefit ul li::before {
    background-image: url(../../images/star-icon.svg);
    background-size: contain;
    position: absolute;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    top: 5px;
}
.packages .item-list .benefit .view {
    background: #058ED2;
    width: auto;
    float: right;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .hospital {
    width: 230px;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 208px;
    background: #fff;
    -webkit-clip-path: polygon(0 0,  100% 0,  29% 72%,  0 100%);
    clip-path: polygon(0 0,  100% 0,  29% 72%,  0 100%);
    padding-right: 110px;
    border-radius: 10px 0px;
}
.packages .item-list .hospital .c-one {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 21px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .hospital img {
    width: 30px !important;
    margin-bottom: 10px;
}
.packages .item-list .botm-content {
    padding: 20px;
    padding-bottom: 25px;
}
.packages .item-list .botm-content p {
    text-align: left;
    float: left;
    width: 50%;
    font-size: 21px;
}
.packages .item-list .botm-content p span {
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .botm-content .color {
    color: #E6771B;
}
.packages .item-list .botm-content .color span {
    font-size: 16px;
    font-family: TitilliumWeb-Regular;
}
.packages .item-list .botm-content .book-now {
    height: 50px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 21px;
    font-family: TitilliumWeb-SemiBold;
}
Packages close */


/*What is nearby open*/
.tl-contr[data-v-53d656cc] {
    margin-bottom: 30px;
    display: inline-block;
}
.tl-contr h1[data-v-53d656cc] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-53d656cc]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.map-contr[data-v-53d656cc]{
    padding-top: 30px;
}
.map-contr img[data-v-53d656cc]{
    max-width: 100%;
}
.search-contr input[data-v-53d656cc] {
    height: 45px;
    border: 1px solid #BCBEC0;
    width: 300px;
    padding-left: 10px;
}
ul.mn-head li[data-v-53d656cc] {
    float: left;
    margin-right: 30px;
    position: relative;
    padding-bottom: 10px;
}
ul.mn-head li.active[data-v-53d656cc]:before {
    width: 55px;
    height: 5px;
    background: #E6771B;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
}
ul.mn-head li.active[data-v-53d656cc]{
    color: #E6771B;
}
/*What is nearby close*/


/*Wheather Details open*/
.tl-contr[data-v-53d656cc] {
    margin-bottom: 30px;
    display: inline-block;
}
.tl-contr h1[data-v-53d656cc] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-53d656cc]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.wheather-contr h1[data-v-53d656cc]{
    font-size: 21px;
    margin-bottom: 15px;
}
.wheather-contr h2[data-v-53d656cc]{
    font-size: 24px;
    margin-top: 10px;
}
.wheather-contr h3[data-v-53d656cc]{
    font-size: 18px;
    color: #BCBEC0;
}
.tl-contr select[data-v-53d656cc] {
    border: 1px solid #E6771B;
    border-radius: 10px;
    width: 150px;
    height: 32px;
    padding-left: 10px;
    float: right;
    font-size: 14px;
}
/*Wheather Details close*/



/*Similar Hospitals open*/
.best-doctor-contr[data-v-53d656cc] {
    padding-top: 10px;
    padding-bottom: 50px !important;
}
.best-doctor-contr img[data-v-53d656cc] {
    border: 30px solid #F1F3F5;
    border-radius: 100%;
    width: auto !important;
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px;
}
.best-doctor-contr h2.inline[data-v-53d656cc] {
    display: inline-flex;
    margin-top: 10px;
    font-size: 18px;
}
.best-doctor-contr h2.inline img[data-v-53d656cc] {
    border: none;
    border-radius: 0;
    margin-right: 10px;
    margin-bottom: 0;
    width: 20px !important;
}
.best-doctor-contr h3[data-v-53d656cc] {
    color: #898890;
}
.best-hh[data-v-53d656cc] {
    padding-bottom: 0;
}
.best-hh img[data-v-53d656cc] {
    border: none;
    border-radius: 10px;
    object-fit: cover;
}
.best-hh h2.inline[data-v-53d656cc] {
    font-family: TitilliumWeb-Regular;
}
.best-hh h2.inline img[data-v-53d656cc] {
    margin-top: 0;
    object-fit: contain;
}

/*Similar Hospitals close*/



/*right side section open*/



/*form-section open*/
.right-sec[data-v-53d656cc] {
    padding-left: 60px;
}
.right-sec .form-contr[data-v-53d656cc] {
    background: #E6771B;
    color: #fff;
    margin-top: 50px;
    padding: 50px 30px;
}
.right-sec .form-contr .form[data-v-53d656cc] {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 0px 15px;
    background: #fff;
    color: #213B4D;
    position: relative;
}
.right-sec .form-contr .mnt[data-v-53d656cc] {
    margin-top: 15px;
}
.right-sec .form-contr textarea[data-v-53d656cc] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px;
}
.right-sec .form-contr .inputfile[data-v-53d656cc] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.right-sec .form-contr span[data-v-53d656cc] {
    line-height: 50px;
}
.right-sec .form-contr .inputfile+label[data-v-53d656cc] {
    cursor: pointer;
}
.right-sec .form-contr .no-js .inputfile+label[data-v-53d656cc] {
    display: none;
}
.right-sec .form-contr .inputfile:focus+label[data-v-53d656cc], .right-sec .form-contr .inputfile.has-focus+label[data-v-53d656cc] {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.right-sec .form-contr .inputfile+label svg[data-v-53d656cc] {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    position: absolute;
    right: 15px;
    top: 15px;
}
/*form-section close*/



/*plan my medical trip open*/
.trip[data-v-53d656cc] {
    margin-top: 40px;
}
.trip-list[data-v-53d656cc] {
    position: relative;
    min-height: 135px;
    background: #FFFFFF;
    box-shadow: 0px 6px 21px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin-bottom: 20px;
}
.trip-contr[data-v-53d656cc]{
    background-color: #058ED2;
    color:#fff;
}
.trip-list img[data-v-53d656cc] {
    max-width: 90px;
    position: absolute;
    right: 15px;
    top: 23px;
}
.trip-list h2[data-v-53d656cc] {
    font-size: 21px;
    margin-bottom: 8px;
    padding-left: 30px;
    padding-right: 115px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.trip-list h2 span[data-v-53d656cc] {
    color: #E6771B;
}
/*plan my medical trip close*/

/*right side section close*/
.expert-btn[data-v-53d656cc]{
    font-weight: bold;
}
.hs-cll[data-v-53d656cc] {
    padding-right: 105px;
}
.nmbb[data-v-53d656cc]{
    margin-top: 0;
}
.packages[data-v-53d656cc] {
    padding-top: 30px;
    padding-bottom: 40px;
    margin-bottom: 50px;
    margin-top: 40px;
    display: inline-block;
}
@media all and (max-width: 1199px) {
.right-sec[data-v-53d656cc] {
        padding-left: 20px;
}
.hs-cll-main[data-v-53d656cc]{
        width: 100%;
}
}
@media all and (max-width: 991px) {
.right-sec[data-v-53d656cc]{
        width: 100%;
        padding: 0;
}
.left-hs[data-v-53d656cc]{
        width: 100%;
}
.right-sec .form-contr[data-v-53d656cc]{
        margin-top: 0;
}
.bks-cn[data-v-53d656cc]{
        width: 100%;
        text-align: center;
}
.book-bttn[data-v-53d656cc] {
        float: none;
        display: inline-block;
        margin-bottom: 0;
}
.book-ln img[data-v-53d656cc]{
        position: static;
        position: initial;
}
.mn-head[data-v-53d656cc]{
        border: none;
}
ul.mn-head li[data-v-53d656cc] {
        padding-bottom: 5px;
        margin-bottom: 10px;
}
.hs-cll-main[data-v-53d656cc]{
        width: 50%;
}
}
@media all and (max-width: 767px) {
.hs-cll-main[data-v-53d656cc]{
        width: 100%;
}
.bn-sec-main[data-v-53d656cc]{
        padding: 0;
}
.bn-one[data-v-53d656cc]{
        width: 100%;
}
.bn-two[data-v-53d656cc]{
        width: 100%;
}
.cn-section[data-v-53d656cc] {
        padding: 20px;
        font-size: 18px;
        line-height: 28px;
}
.right-sec[data-v-53d656cc] {
        padding: 0px 10px;
}
.wheather-list[data-v-53d656cc]{
        width: 33.333%;
}
.wheather-contr h1[data-v-53d656cc] {
        font-size: 18px;
}
.wheather-contr h2[data-v-53d656cc] {
        font-size: 18px;
}
.wheather-contr h3[data-v-53d656cc] {
        font-size: 16px;
}
.wheather-list img[data-v-53d656cc] {
        max-width: 60px;
}
.core-contr h2[data-v-53d656cc]{
        font-size: 16px;
}
.best-doctor-contr[data-v-53d656cc]{
        padding-bottom: 10px !important;
}
.mll-mb[data-v-53d656cc]{
        margin-bottom: 0;
}
}
@media all and (max-width: 599px) {
ul.core-contr li[data-v-53d656cc] {
        width: 50%;
        margin-bottom: 0;
}
ul.core-contr li h2 img[data-v-53d656cc] {
        margin-right: 5px;
        max-width: 30px;
}
.sf-cn[data-v-53d656cc] {
        margin-bottom: 0;
}
.pph-cn[data-v-53d656cc]{
        margin-top: 0;
}
.bn-sec-main img[data-v-53d656cc]{
        height: 320px;
}
.bn-two img[data-v-53d656cc]{
        height: 150px;
}
}
@media all and (max-width: 480px) {
.doctors-listing-section[data-v-53d656cc]{
        padding: 0px;
        padding-top: 25px;
}
}
@media all and (max-width: 420px) {
.hs-cll[data-v-53d656cc] {
        float: left;
        padding-left: 15px;
        padding-right:0px;
}
.links[data-v-53d656cc]{
        float: right;
        position: static;
        position: initial;
        padding-right: 15px;
        padding-top: 15px;
}
.doctor-img[data-v-53d656cc]{
        position: static;
        position: initial;
}
.doctors-list[data-v-53d656cc]{
        height: auto;
        padding-left: 0;
}
.doctor-img img[data-v-53d656cc]{
        width: 65px;
        height: 65px;
        margin-top: 10px;
}
.search-contr input[data-v-53d656cc]{
        width: 100%;
}
}