.tl-contr[data-v-9ab33e2c] {
    margin-bottom: 30px;
    display: inline-block
}
.tl-contr h1[data-v-9ab33e2c] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px
}
.tl-contr h1[data-v-9ab33e2c]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0
}
.tl-contr label[data-v-9ab33e2c] {
    padding-top: 6px
}
.tl-contr ul.country-contr[data-v-9ab33e2c] {
    float: right;
    margin-bottom: 5px
}
.tl-contr ul.country-contr li[data-v-9ab33e2c] {
    float: left;
    border-bottom: 6px solid #F1F3F5;
    padding-left: 20px
}
.tl-contr ul.country-contr li select[data-v-9ab33e2c] {
    border: 1px solid #E6771B;
    border-radius: 10px;
    padding: 5px 15px
}
.packages[data-v-9ab33e2c] {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #F1F3F5
}
.packages .item-list[data-v-9ab33e2c] {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative
}
.packages .item-list img[data-v-9ab33e2c] {
    width: 100%
}
.packages .item-list h2[data-v-9ab33e2c] {
    text-align: left;
    padding: 20px;
    margin-bottom: 0
}
.packages .item-list .benefit[data-v-9ab33e2c] {
    padding: 20px;
    background: #d0eaf7;
    position: relative
}
.packages .item-list .benefit h2[data-v-9ab33e2c] {
    padding: 0;
    padding-bottom: 15px
}
.packages .item-list .benefit ul[data-v-9ab33e2c] {
    margin: 0
}
.packages .item-list .benefit ul li[data-v-9ab33e2c] {
    text-align: left;
    position: relative;
    padding-left: 30px;
    line-height: 28px
}
.packages .item-list .benefit ul li[data-v-9ab33e2c]::before {
    background-image: url(../../images/star-icon.svg);
    background-size: contain;
    position: absolute;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    top: 5px
}
.packages .item-list .benefit .view[data-v-9ab33e2c] {
    background: #058ED2;
    width: auto;
    float: right;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: TitilliumWeb-SemiBold
}
.packages .item-list .hospital[data-v-9ab33e2c] {
    width: 230px;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 208px;
    background: #fff;
    -webkit-clip-path: polygon(0 0, 100% 0, 29% 72%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 29% 72%, 0 100%);
    padding-right: 110px;
    border-radius: 10px 0px
}
.packages .item-list .hospital .c-one[data-v-9ab33e2c] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 21px;
    font-family: TitilliumWeb-SemiBold
}
.packages .item-list .hospital img[data-v-9ab33e2c] {
    width: 30px !important;
    margin-bottom: 10px
}
.packages .item-list .botm-content[data-v-9ab33e2c] {
    padding: 20px;
    padding-bottom: 25px
}
.packages .item-list .botm-content p[data-v-9ab33e2c] {
    text-align: left;
    float: left;
    width: 50%;
    font-size: 21px
}
.packages .item-list .botm-content p span[data-v-9ab33e2c] {
    font-family: TitilliumWeb-SemiBold
}
.packages .item-list .botm-content .color[data-v-9ab33e2c] {
    color: #E6771B
}
.packages .item-list .botm-content .color span[data-v-9ab33e2c] {
    font-size: 16px;
    font-family: TitilliumWeb-Regular
}
.packages .item-list .botm-content .book-now[data-v-9ab33e2c] {
    height: 50px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 21px;
    font-family: TitilliumWeb-SemiBold
}
.packages .best-package[data-v-9ab33e2c] {
    background: #E6771B;
    color: #fff;
    border-radius: 15px;
    padding: 60px 90px;
    position: relative;
    padding-right: 280px;
    margin-top: 30px
}
.packages .best-package h2[data-v-9ab33e2c] {
    font-size: 64px
}
.packages .best-package p[data-v-9ab33e2c] {
    font-size: 24px
}
.packages .best-package .contact[data-v-9ab33e2c] {
    background: #FFFFFF;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    color: #213B4D;
    line-height: 50px;
    position: absolute;
    right: 60px;
    top: 60px;
    font-size: 24px
}
@media all and (max-width: 1440px) {
.packages .item-list .botm-content .book-now[data-v-9ab33e2c] {
        font-size: 16px;
}
}
@media all and (max-width: 1199px) {
.packages .item .hospital[data-v-9ab33e2c] {
        width: 100%;
        -webkit-clip-path: none;
        clip-path: none;
        height: auto;
        background: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border-radius: 0;
        padding-left: 48px;  
        height: 55px;
        line-height: 34px;
}
.packages .item .hospital img[data-v-9ab33e2c]{
        position: absolute;
        left: 5px;
}
.packages .item .hospital .c-one[data-v-9ab33e2c] {
        -webkit-line-clamp: 1;
}
}
@media all and (max-width: 767px) {
.packages .item .botm-content .book-now[data-v-9ab33e2c] {
        font-size: 14px;
        height: auto;
}
.packages[data-v-9ab33e2c] {
        padding-top: 40px;
        padding-bottom: 40px;
}
}