/*form section open*/
.form-contr[data-v-71b0c9f4] {
    color: #fff;
    padding: 20px 30px;
    width: 390px;
    float: right;
    border-radius: 10px;
    background: #EDF2F7;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.07);
    margin-top: 50px;
    margin-right: 70px;
}
.form-contr .form[data-v-71b0c9f4] {
    height: 45px;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 0px 15px;
    background: #fff;
    color: #213B4D;
    position: relative;
    font-size: 14px;
}
.form-contr label[data-v-71b0c9f4] {
    color: #213B4D;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 10px;
}
.form-contr .mnt[data-v-71b0c9f4] {
    margin-top: 15px;
}
.form-contr textarea[data-v-71b0c9f4] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px;
}
.form-contr .inputfile[data-v-71b0c9f4] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.form-contr span[data-v-71b0c9f4] {
    line-height: 50px;
}
.form-contr .inputfile+label[data-v-71b0c9f4] {
    cursor: pointer;
}
.form-contr .no-js .inputfile+label[data-v-71b0c9f4] {
    display: none;
}
.form-contr .inputfile:focus+label[data-v-71b0c9f4], .form-contr .inputfile.has-focus+label[data-v-71b0c9f4] {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.form-contr .inputfile+label svg[data-v-71b0c9f4] {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    position: absolute;
    right: 15px;
    top: 15px;
}
.forms-cn[data-v-71b0c9f4] {
    position: relative;
    margin-top: -400px;
}
.submit1[data-v-71b0c9f4]{
    background-color: #E6771B !important;
    color: #fff !important;
}
.bn-content[data-v-71b0c9f4] {
    color: #fff;
    padding-top: 80px;
    padding-left: 70px;
}
/*form section close*/









/*second section open*/
.show-list[data-v-71b0c9f4] {
    background: #058ED2;
    border-radius: 15px;
    color: #fff;
    text-align: center;
    min-height: 240px;
    margin-top: 50px;
    position: relative;
    transition: 0.3s;
}
.img-icon[data-v-71b0c9f4]{
    width: 130px;
    height: 130px;
    border-radius: 100%;
    background-color: #fff;
    margin: auto;
    border: 5px solid #058ED2;
    margin-top: -50px;
    display: inline-block;
}
.img-icon img[data-v-71b0c9f4] {
    width: 70px;
    padding-top: 30px;
}
.show-list h2[data-v-71b0c9f4] {
    padding: 0px 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding-top: 75px;
}
.show-list[data-v-71b0c9f4]:hover {
    background: #E6771B;
}
.show-list:hover .img-icon[data-v-71b0c9f4]{
    border-color: #E6771B;
}
/*second section close*/



/*trusted brands open*/
.ts-logo[data-v-71b0c9f4] {
    width: 20%;
    min-height: 100px;
    margin-top: 20px;
}
.ts-logo img[data-v-71b0c9f4] {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.tl-contr h1[data-v-71b0c9f4] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-71b0c9f4]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.trusted-brands[data-v-71b0c9f4]{
    margin-top: 50px;
}
/*trusted brands close*/

/*attractive profile open*/
.attractive-profile[data-v-71b0c9f4] {
    background: #EDF2F7;
}
.tl-contr h1[data-v-71b0c9f4] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-71b0c9f4]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.tl-contr span[data-v-71b0c9f4]{
    color: #E6771B;
}
.attractive-profile .tl-contr[data-v-71b0c9f4] {
    padding-top: 50px;
}
.attractive-profile ul li[data-v-71b0c9f4]::before {
    background-image: url(../../images/tick-icon.svg);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 25px;
    height: 20px;
    background-size: 25px;
}
.attractive-profile ul li[data-v-71b0c9f4] {
    position: relative;
    padding-left: 40px;
    line-height: 34px;
}
/*attractive profile close*/








/*Some Numbers / Statistics open*/
.tl-contr h1[data-v-71b0c9f4] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-71b0c9f4]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.tsc-logo[data-v-71b0c9f4] {
    width: 20%;
    min-height: 100px;
    margin-top: 20px;
}
.tsc-logo h1[data-v-71b0c9f4]{
    color: #E6771B;
}
/*Some Numbers / Statistics close*/



/*offer open*/
.offer-contr[data-v-71b0c9f4] {
    background: #E6771B;
    border-radius: 15px;
    color: #fff;
    padding: 20px 30px;
}
/*offer close*/
.fmbg-main[data-v-71b0c9f4]{
    margin-top: 50px;
}
.fm-bg[data-v-71b0c9f4] {
    height: 400px;
    width: 100%;
    object-fit: cover;
}
section[data-v-71b0c9f4] {
    overflow-x: hidden;
}
@media all and (max-width: 991px) {
.show-main .col-3[data-v-71b0c9f4]{
        width: 50%;
}
.attractive-profile .col-6[data-v-71b0c9f4] {
        width: 100%;
}
.attractive-profile img[data-v-71b0c9f4] {
        max-width: 350px;
        width: 100%;
}
.attractive-profile .tl-contr[data-v-71b0c9f4] {
        padding-top: 0px;
}
.form-contr[data-v-71b0c9f4] {
        width: 100%;
        margin-right: 15px;
}
.bn-content[data-v-71b0c9f4]{
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 30px;
}
}
@media all and (max-width: 767px) {
.forms-cn .col-6[data-v-71b0c9f4]{
        width: 100%;
}
.form-contr[data-v-71b0c9f4] {
        margin: 0;
}
.form-main[data-v-71b0c9f4]{
        padding: 0px 20px;
}
.bn-content[data-v-71b0c9f4] {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
}
.fmbg-main[data-v-71b0c9f4] {
        margin-top: 30px;
}
.form-contr .form[data-v-71b0c9f4]{
        height: 40px;
}
}
@media all and (max-width: 420px) {
.show-main .col-3[data-v-71b0c9f4]{
        width: 100%;
}
}