.hospital[data-v-aef45dfc] {
    background: #F1F3F5;
    padding: 20px;
    padding-top: 50px;
    border-radius: 10px;
    margin-top: 10px !important;
    position: relative;
    z-index: 1;
}
.hospital-list[data-v-aef45dfc] {
    position: relative;
    min-height: 220px;
    margin-bottom: 25px;
    padding-left: 350px;
}
.hospital-img[data-v-aef45dfc] {
    position: absolute;
    left: 0;
    width: 320px;
    height: 220px;
}
.hospital-img img[data-v-aef45dfc] {
    max-width: 100%;
    position: static !important;
    position: initial !important;
    width: 320px;
    height: 200px;
    object-fit: cover;
    background-color: #fff;
}
.btcn[data-v-aef45dfc] {
    background: #00AAC4;
    border-radius: 5px;
    color: #fff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btcn1[data-v-aef45dfc] {
    background: #E6771B;
    border-radius: 5px;
    color: #ffffff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 15px;
}
.btcn[data-v-aef45dfc]:hover {
    background-color: #07899d;
    color: #fff;
}
.btcn1[data-v-aef45dfc]:hover {
    background-color: #cf660f;
    color: #ffffff;
}
.tip-list img[data-v-aef45dfc] {
    position: static !important;
    position: initial !important;
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.tip-list[data-v-aef45dfc] {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}
.tip-list p[data-v-aef45dfc] {
    font-size: 21px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
}
.t1[data-v-aef45dfc] {
    color: #E6771B;
    font-size: 14px;
    float: left;
}
.tip-tl[data-v-aef45dfc] {
    color: #058ED2;
    text-align: left;
    margin-bottom: 15px;
}
.hospital-left[data-v-aef45dfc] {
    padding-right: 50px;
    width: 20%;
    text-align: left;
    border-right: 1px solid #C4C4C4;
}
.hospital-right[data-v-aef45dfc] {
    padding-left: 50px;
    padding-right: 20px;
    width: 80%;
}
.hospital-list[data-v-aef45dfc]:last-child {
    border: none;
}
.hs-detail[data-v-aef45dfc] {
    text-align: left;
    color: #213B4D;
}
.star i[data-v-aef45dfc] {
    color: #D6DADE;
    font-size: 14px;
    margin-right: 5px;
}
.star i.active[data-v-aef45dfc] {
    color: #e6771b;
}
.hs-detail p[data-v-aef45dfc] {
    margin-bottom: 0;
}
.star[data-v-aef45dfc] {
    margin: 5px 0px;
}
.hospital-left ul li[data-v-aef45dfc] {
    width: -webkit-max-content;
    width: max-content;
    position: relative;
    padding: 8px 0px;
    color: #213B4D;
}
.hospital-left ul li.active[data-v-aef45dfc]:before {
    position: absolute;
    content: "";
    height: 4px;
    background: #E6771B;
    width: 100%;
    bottom: 3px;
}