/*first section open*/
.tl-contr[data-v-4b833ef5] {
    margin-bottom: 15px;
    display: inline-block;
    margin-top: 50px;
}
.tl-contr h1[data-v-4b833ef5] {
    margin-bottom: 20px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-4b833ef5]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.ml-top[data-v-4b833ef5]{
    margin-top: 90px;
}
.tl-contr h2[data-v-4b833ef5] {
    font-size: 18px;
    margin-top: 20px;
}
.tl-contr label[data-v-4b833ef5] {
    padding-top: 6px;
}
.tl-contr ul.country-contr[data-v-4b833ef5] {
    float: right;
    margin-bottom: 5px;
}
.tl-contr ul.country-contr li[data-v-4b833ef5] {
    float: left;
    padding-left: 20px;
}
.tl-contr ul.country-contr li select[data-v-4b833ef5] {
    border: 1px solid #E6771B;
    border-radius: 10px;
    padding: 5px 15px;
}
/*first section close*/




/*second section open*/
.packages[data-v-4b833ef5] {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #F1F3F5;
}
.packages .item-list[data-v-4b833ef5] {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative;
}
.packages .item-list .image[data-v-4b833ef5] {
    display: block;
    width: 100%;
    height: auto;
}
.packages .item-list .overlay[data-v-4b833ef5] {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}
.packages .item-list .hs-div:hover .overlay[data-v-4b833ef5] {
    bottom: 0;
    height: 100%}
.packages .item-list .text[data-v-4b833ef5] {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,  -50%);
    transform: translate(-50%,  -50%);
    text-align: center;
    color: #E6771B;
    width: 100%;
    padding: 20px;
}
.packages .item-list .text a[data-v-4b833ef5] {
    color: #213B4D;
    font-family: TitilliumWeb-SemiBold;
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
}
.packages .item-list img[data-v-4b833ef5] {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}
.packages .item-list h2[data-v-4b833ef5] {
    text-align: left;
    margin: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 32px;
    margin-bottom: 10px;
}
.packages .item-list .content[data-v-4b833ef5] {
    padding: 20px;
    padding-top: 0;
    position: relative;
    padding-left: 45px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 60px;
    line-height: 28px;
    margin-bottom: 20px;
}
.packages .item-list .content svg[data-v-4b833ef5] {
    position: absolute;
    left: 20px;
    top: 4px;
}
.book-now[data-v-4b833ef5] {
    height: 42px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 220px;
    font-size: 18px;
    font-family: TitilliumWeb-SemiBold;
    margin: auto;
    display: table;
    margin-top: 15px;
}
.packages .item-list .text ul[data-v-4b833ef5] {
    padding: 20px 0px;
    text-align: left;
    margin: auto;
    height: 128px;
    overflow: hidden;
    width: -webkit-max-content;
    width: max-content;
    padding-bottom: 0;
    margin-bottom: 20px;
}
.packages .item-list .text ul li[data-v-4b833ef5]{
    position: relative;
    line-height: 34px;
    padding-left: 35px;
}
.packages .item-list .text ul li[data-v-4b833ef5]::before {
    background-image: url(../../images/list-tick-icon.svg);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 20px;
    height: 20px;
    background-size: 20px;
}
/*second section open*/
.text h3[data-v-4b833ef5] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 51px;
}
@media all and (max-width: 1440px) {
.packages .item-list h2[data-v-4b833ef5]{
        line-height: 28px;
}
}
@media all and (max-width: 991px) {
.hss-list[data-v-4b833ef5]{
        width: 50%;
}
}
@media all and (max-width: 767px) {
.packages[data-v-4b833ef5] {
        padding-top: 40px;
        padding-bottom: 20px;
}
.ml-top[data-v-4b833ef5] {
        margin-top: 0;
        width: 100%;
}
.m-fl[data-v-4b833ef5]{
        width: 100%;
}
.tl-contr[data-v-4b833ef5]{
        margin-top: 30px;
}
.tl-contr ul.country-contr li[data-v-4b833ef5]{
        padding-left: 0;
}
.tl-contr ul.country-contr li[data-v-4b833ef5]:last-child {
        margin-left: 5px;
}
.book-now[data-v-4b833ef5]{
        width: 100%;
        font-size: 16px;
}
.text h3[data-v-4b833ef5]{
        height: 46px;
        font-size: 18px;
}
.packages .item-list .text ul li[data-v-4b833ef5]{
        font-size: 16px;
}
.packages .item-list .text[data-v-4b833ef5]{
        font-size: 16px;
}
}
@media all and (max-width: 480px) {
.hss-list[data-v-4b833ef5] {
        width: 100%;
}
.tl-contr ul.country-contr[data-v-4b833ef5] {
        width: 100%;
        margin-top: 10px;
}
.tl-contr ul.country-contr li select[data-v-4b833ef5] {
        padding: 5px 10px;
}
}