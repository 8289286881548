.tl-contr.to-top[data-v-8e62b50b] {
    margin-top: 40px;
}
.tl-contr.to-top h1[data-v-8e62b50b]::before {
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: 20px;
}
.best-doctor-contr[data-v-8e62b50b] {
    padding-top: 50px;
    padding-bottom: 20px;
}
.best-doctor-contr .tl-contr ul.country-contr li[data-v-8e62b50b] {
    float: left;
    padding-left: 30px;
    border-bottom: 6px solid #F1F3F5;
    padding-bottom: 10px;
}
.best-doctor-contr .tl-contr ul.country-contr li img[data-v-8e62b50b] {
    border: none;
    margin-bottom: 0;
}
.best-doctor-contr .tl-contr ul.country-contr li.active[data-v-8e62b50b] {
    border-bottom: 6px solid #E6771B;
}
.best-doctor-contr .tl-contr .radio-btn-contr[data-v-8e62b50b] {
    margin-bottom: 15px;
}
.best-doctor-contr .tl-contr .radio-btn-contr h1[data-v-8e62b50b] {
    margin-bottom: 15px;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn[data-v-8e62b50b] {
    display: block;
    position: relative;
    padding: 5px 10px;
    padding-left: 28px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
    margin-right: 10px;
    color: #B7B7B7;
    margin-top: 5px;
    border-radius: 20px;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn input[data-v-8e62b50b] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn .checkmark[data-v-8e62b50b] {
    position: absolute;
    top: 13px;
    left: 10px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid #213B4D;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn .checkmark[data-v-8e62b50b]:after {
    content: "";
    position: absolute;
    display: none;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn input:checked ~ .checkmark[data-v-8e62b50b]:after {
    display: block;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn .checkmark[data-v-8e62b50b]:after {
    top: -2px;
    left: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E6771B;
}
.best-doctor-contr .tl-contr .radio-btn-contr .radio-btn.active[data-v-8e62b50b] {
    background: #E7E7E7;
}
.best-doctor-contr .radio-btn-contr[data-v-8e62b50b] {
    margin: auto;
    display: table;
    margin-top: 50px;
}
.best-doctor-contr .radio-btn-contr h2[data-v-8e62b50b] {
    font-size: 28px;
    margin-bottom: 25px;
}
.best-doctor-contr .radio-btn-contr .radio-btn[data-v-8e62b50b] {
    display: block;
    position: relative;
    padding: 5px 10px;
    padding-left: 28px;
    cursor: pointer;
    font-size: 21px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 20px;
}
.best-doctor-contr .radio-btn-contr .radio-btn input[data-v-8e62b50b] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.best-doctor-contr .radio-btn-contr .radio-btn .checkmark[data-v-8e62b50b] {
    position: absolute;
    top: 13px;
    left: 10px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid #213B4D;
}
.best-doctor-contr .radio-btn-contr .radio-btn .checkmark[data-v-8e62b50b]:after {
    content: "";
    position: absolute;
    display: none;
}
.best-doctor-contr .radio-btn-contr .radio-btn input:checked ~ .checkmark[data-v-8e62b50b]:after {
    display: block;
}
.best-doctor-contr .radio-btn-contr .radio-btn .checkmark[data-v-8e62b50b]:after {
    top: -2px;
    left: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E6771B;
}
.best-doctor-contr .radio-btn-contr .radio-btn.active[data-v-8e62b50b] {
    background: #E7E7E7;
}
.best-doctor-contr img[data-v-8e62b50b] {
    border: 15px solid #fff;
    border-radius: 100%;
    width: 18px !important;
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px;
    -webkit-filter: drop-shadow(4px 4px 11px rgba(0, 0, 0, 0.07));
            filter: drop-shadow(4px 4px 11px rgba(0, 0, 0, 0.07));
}
.best-doctor-contr img.img-fluid[data-v-8e62b50b] {
    height: 200px;
    width: 200px !important;
}
.best-doctor-contr h2.inline[data-v-8e62b50b] {
    display: inline-flex;
    margin-top: 10px;
}
.best-doctor-contr h2.inline img[data-v-8e62b50b] {
    border: none;
    border-radius: 0;
    margin-right: 5px;
    margin-bottom: 0;
}
.ps-slide[data-v-8e62b50b]{
    margin: 0px -30px;
}
.ds-item[data-v-8e62b50b]{
    padding-top: 25px;
    background: linear-gradient(180deg, #F1F3F5 0%, rgba(241, 243, 245, 0) 96.87%);
    border-radius: 26px;
}
ul.country-contr[data-v-8e62b50b] {
    position: absolute;
    right: 0px;
}
li.choose-item[data-v-8e62b50b] {
    border: none !important;
}
@media all and (max-width: 1440px) {
.best-doctor-contr[data-v-8e62b50b]{
        padding-top: 70px;
}
.best-doctor-contr img[data-v-8e62b50b] {
        width: 20px !important;
}
.tl-contr.to-top[data-v-8e62b50b] {
        margin-top: 50px;
        margin-bottom: 0;
}
}
@media all and (max-width: 991px) {
.best-doctor-contr[data-v-8e62b50b]{
        padding-top: 50px;
}
.best-doctor-contr .tl-contr[data-v-8e62b50b]{
        margin-bottom: 0;
}
.tl-contr.to-top[data-v-8e62b50b]{
        margin-top: 40px;
}
}
@media all and (max-width: 767px) {
.best-doctor-contr .radio-btn-contr h2[data-v-8e62b50b]{
        font-size: 18px;
}
.best-doctor-contr .radio-btn-contr .radio-btn[data-v-8e62b50b]{
        font-size: 18px;
}
}