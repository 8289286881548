
.packages[data-v-3f0194a2] {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #F1F3F5;
}
.packages .item[data-v-3f0194a2] {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.packages .item h2[data-v-3f0194a2] {
    text-align: left;
    margin: 12px 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.packages .item .benefit[data-v-3f0194a2] {
    padding: 20px;
    background: #d0eaf7;
    position: relative;
}
.packages .item .benefit h2[data-v-3f0194a2] {
    padding: 0;
    padding-bottom: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    min-height: 21px;
    max-height: 21px;
    font-size: 21px;
    margin: 0;
    margin-bottom: 20px;
}
.packages .item .benefit ul[data-v-3f0194a2] {
    margin: 0;
    height: 84px;
    overflow: hidden;
    padding-right: 140px;
}
.packages .item .benefit ul li[data-v-3f0194a2] {
    text-align: left;
    position: relative;
    padding-left: 30px;
    line-height: 28px;
}
.packages .item .benefit ul li[data-v-3f0194a2]::before {
    background-image: url(../images/star-icon.svg);
    background-size: contain;
    position: absolute;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    top: 5px;
}
.packages .item .benefit .view[data-v-3f0194a2] {
    background: #058ED2;
    width: auto;
    float: right;
    color: #fff;
    border-radius: 5px;
    padding: 6px 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item .hospital[data-v-3f0194a2] {
    width: 230px;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 208px;
    background: #fff;
    -webkit-clip-path: polygon(0 0,  100% 0,  29% 72%,  0 100%);
    clip-path: polygon(0 0,  100% 0,  29% 72%,  0 100%);
    padding-right: 110px;
    border-radius: 10px 0px;
}
.packages .item .hospital .c-one[data-v-3f0194a2] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 18px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item .hospital img[data-v-3f0194a2] {
    width: 30px !important;
    margin-bottom: 10px;
}
.imc img[data-v-3f0194a2]{
    width: 100%;
}
.packages .item .botm-content[data-v-3f0194a2] {
    padding: 20px;
    padding-bottom: 25px;
}
.packages .item .botm-content p[data-v-3f0194a2] {
    text-align: left;
    float: left;
    width: 50%;
    font-size: 20px;
}
.packages .item .botm-content p span[data-v-3f0194a2] {
    font-family: TitilliumWeb-SemiBold;
}
.packages .item .botm-content .color[data-v-3f0194a2] {
    color: #E6771B;
}
.packages .item .botm-content .color span[data-v-3f0194a2] {
    font-size: 16px;
    font-family: TitilliumWeb-Regular;
}
.packages .item .botm-content .book-now[data-v-3f0194a2] {
    height: 40px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    font-size: 18px;
    font-family: TitilliumWeb-SemiBold;
}
.ps-slide[data-v-3f0194a2]{
    margin: 0px -30px;
}
.h-detail[data-v-3f0194a2]{
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    margin: 50px 0px;
    display: inline-block;
}
@media all and (max-width: 1440px) {
.packages .item .botm-content .book-now[data-v-3f0194a2]{
        font-size: 16px;
}
.packages .item .botm-content p[data-v-3f0194a2]{
        font-size: 18px;
}
.packages .item .benefit .view[data-v-3f0194a2]{
        position: static;
        position: initial;
        float: none;
        max-width: 120px;
        padding: 0px 10px;
        margin-top: 10px;
        height: 42px;
        line-height: 42px;
}
.packages .item .botm-content .book-now[data-v-3f0194a2]{
        height: 42px;
        border-radius: 5px;
}
.packages[data-v-3f0194a2] {
        padding-top: 70px;
        padding-bottom: 70px;
}
.packages .item .benefit h2[data-v-3f0194a2]{
        font-size: 18px;
}
.packages .item .benefit ul[data-v-3f0194a2]{
        padding-right: 0;
}
}
@media all and (max-width: 1199px) {
.packages .item .hospital[data-v-3f0194a2] {
        width: 100%;
        -webkit-clip-path: none;
        clip-path: none;
        height: auto;
        background: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border-radius: 0;
        padding-left: 48px;  
        height: 55px;
        line-height: 34px;
}
.packages .item .hospital img[data-v-3f0194a2]{
        position: absolute;
        left: 5px;
}
.packages .item .hospital .c-one[data-v-3f0194a2] {
        -webkit-line-clamp: 1;
}
}
@media all and (max-width: 767px) {
.packages .item .botm-content .book-now[data-v-3f0194a2] {
        font-size: 14px;
        height: auto;
}
.packages[data-v-3f0194a2] {
        padding-top: 40px;
        padding-bottom: 40px;
}
.h-detail[data-v-3f0194a2]{
        margin: 25px 0px;
        margin-bottom: 30px;
}
.nmbb[data-v-3f0194a2]{
        margin-bottom: 0;
}
}