
.map-section[data-v-efc7ed50] {
    background-image: url(../images/map-section-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    padding-top: 70px;
    padding-bottom: 60px;
    color: #fff;
    width: 100%}
.map-section .hs-fea ul li[data-v-efc7ed50] {
    float: left;
    margin-right: 3%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    width: 30%;
    height: 130px;
}
.about-section .teachable-contr .caro-contr .item[data-v-efc7ed50] {
    padding-top: 38px;
}
.map-section .hs-fea ul li .cn-center[data-v-efc7ed50] {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.map-section .hs-fea ul li p[data-v-efc7ed50] {
    font-size: 16px;
}
.map-section .hs-fea ul li h1[data-v-efc7ed50] {
    font-size: 48px;
}
.map-section .fes-cn[data-v-efc7ed50] {
    float: left;
    width: 100%;
    margin: 15px 0px;
}
.map-section .fes-cn p[data-v-efc7ed50] {
    font-size: 24px;
    float: left;
    float: left;
    width: 33.333%}
.tl-contr h1[data-v-efc7ed50] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-efc7ed50]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-efc7ed50] {
    padding-top: 100px;
}
.to-top h1[data-v-efc7ed50]::before {
    margin: auto;
    left: 0;
    right: 0;
}
@media all and (max-width: 1600px) {
}
@media all and (max-width: 991px) {
.map-section .col-6[data-v-efc7ed50] {
        width: 100%;
        flex: 100% 1;
}
.map-content[data-v-efc7ed50]{
        margin-top: 20px;
}
}
@media all and (max-width: 599px) {
.map-section[data-v-efc7ed50]{
        margin: 0 !important;
        padding: 40px 0px 25px 0px !important;
}
}
@media all and (max-width: 480px) {
.map-section .hs-fea ul li[data-v-efc7ed50]{
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
}
.map-section .fes-cn p[data-v-efc7ed50]{
        width: 100%;
}
}