/*banner section open*/
.main-banner .banner-content .search-contr[data-v-cb8b817a]{
    background: #FFFFFF;
    padding: 25px;
    padding-bottom: 50px;
    margin-top: 0px;
    float: left;
    width: 100%;
    border-radius: 0;
    border: none;
    height: 190px;
}
.search-right[data-v-cb8b817a] {
    background: #3D4461;
    height: 190px;
    position: relative;
}
.search-right img[data-v-cb8b817a] {
    position: absolute;
    bottom: 0px;
    left: 50px;
}
a.find-doctor[data-v-cb8b817a] {
    background: #00B3FF;
    color: #fff;
    height: 50px;
    border-radius: 10px;
    display: inline-block;
    width: 200px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.search-input input[data-v-cb8b817a] {
    background: #FFFFFF !important;
    border: 1px solid #BCBEC0 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    height: 45px !important;
    padding-left: 15px !important;
    width: 380px !important;
    margin-right: 20px;
}
.search-input select[data-v-cb8b817a] {
    background: #FFFFFF;
    border: 1px solid #BCBEC0;
    box-sizing: border-box;
    border-radius: 5px;
    height: 45px;
    padding-left: 15px;
    width: 220px !important;
    margin-right: 78px;
}
.search-input button[data-v-cb8b817a] {
    width: auto !important;
    border-radius: 0 !important;
    height: auto !important;
    background: transparent !important;
    border: none !important;
    left: auto !important;
    bottom: 0 !important;
}
.main-banner .banner-content[data-v-cb8b817a] {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    max-width: 1300px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.search-contr h2[data-v-cb8b817a] {
    color: #213B4D;
}
/*banner section close*/


/*first section open*/
.tsc-contr .l-one[data-v-cb8b817a] {
    float: left;
    width: 25%;
}
.l-one img[data-v-cb8b817a] {
    background: linear-gradient(201.11deg, rgba(5, 142, 210, 0.17) 2.76%, rgba(255, 255, 255, 0.31) 84.34%);
    border-radius: 10px;
}
.l-one h3[data-v-cb8b817a] {
    font-size: 21px;
    margin-top: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 29px;
}
.tsc-contr[data-v-cb8b817a] {
    margin-top: 50px !important;
}
.l-one p[data-v-cb8b817a] {
    margin-top: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 52px;
}
.tl-contr[data-v-cb8b817a] {
    margin-bottom: 10px;
    display: inline-block;
}
.tl-contr h1[data-v-cb8b817a] {
    position: relative;
    padding-top: 15px;
    margin-bottom: 10px;
}
.tl-contr h1[data-v-cb8b817a]::before {
    content: "";
    background: #e6771b;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.tl-contr p[data-v-cb8b817a]{
    float: left;
    width: 100%;
}
/*first section close*/
.speciality-bn img[data-v-cb8b817a] {
    height: 300px;
    width: 100%;
    object-fit: cover;
}
@media all and (max-width: 991px) {
.tsc-contr .l-one[data-v-cb8b817a]{
        width: 33.333%;
}
.tsc-contr[data-v-cb8b817a]{
        padding-left: 20px;
        padding-right: 20px;
}
}
@media all and (max-width: 767px) {
.l-one h3[data-v-cb8b817a]{
        font-size: 18px;
        height: 25px;
}
}
@media all and (max-width: 599px) {
.tsc-contr .l-one[data-v-cb8b817a]{
        width: 50%;
}
}
@media all and (max-width: 479px) {
.tsc-contr .l-one[data-v-cb8b817a] {
        width: 100%;
}
.tsc-contr[data-v-cb8b817a]{
        padding-left: 0px;
        padding-right: 0px;
}
}