
.award-section .ns-contr[data-v-1cd637a8] {
    background: #F1F3F5;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-between;
}
.award-section .ns-contr .award[data-v-1cd637a8] {
    width: 30%}
.award-section .ns-contr .award .award-list[data-v-1cd637a8] {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}
.award-section .ns-contr .award .award-list .position[data-v-1cd637a8] {
    text-align: center;
    color: #fff;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
}
.award-section .ns-contr .award .award-list img.ml[data-v-1cd637a8] {
    -webkit-filter: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.25));
}
.award-section .ns-contr img.l2[data-v-1cd637a8] {
    max-width: 200px;
}
.award-section .ns-contr img.l1[data-v-1cd637a8] {
    margin-top: 25px;
}
.award-section .ns-contr img.l3[data-v-1cd637a8] {
    margin-top: 25px;
}
.tl-contr h1[data-v-1cd637a8] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-1cd637a8]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-1cd637a8] {
    padding-top: 50px;
}
.to-top h1[data-v-1cd637a8]::before {
    margin: auto;
    left: 0;
    right: 0;
}
@media all and (max-width: 1440px) {
.to-top[data-v-1cd637a8] {
        padding-top: 70px;
}
.award-section .ns-contr[data-v-1cd637a8]{
        padding-top: 30px;
        padding-bottom: 30px;
}
}
@media all and (max-width: 1199px) {
.award-section .ns-contr img.l2[data-v-1cd637a8]{
        max-width: 150px;
}
}
@media all and (max-width: 991px) {
.position.v-center img[data-v-1cd637a8] {
        width: 30px;
}
.position.v-center h2[data-v-1cd637a8]{
        font-size: 18px;
}
.award-section .ns-contr img.l1[data-v-1cd637a8] {
        margin-top: 0px;
        width: 50px;
}
.award-section .ns-contr img.l3[data-v-1cd637a8] {
        margin-top: 0px;
        width: 50px;
}
.to-top[data-v-1cd637a8] {
        padding-top: 50px;
}
}
@media all and (max-width: 767px) {
.award-center[data-v-1cd637a8] {
        display: none;
}
.award-section .ns-contr .award[data-v-1cd637a8] {
        width: 48%;
}
.award-section .ns-contr .award .award-list[data-v-1cd637a8] {
        margin-top: 10px;
        margin-bottom: 10px;
}
}
@media all and (max-width: 480px) {
.award-section .ns-contr[data-v-1cd637a8] {
        padding-top: 40px;
        padding-bottom: 40px;
}
}
@media all and (max-width: 480px) {
.award-section .ns-contr[data-v-1cd637a8]{
        display: inline !important;
        display: initial !important;
        float: left;
        width: 100%;
}
.award-section .ns-contr .award[data-v-1cd637a8] {
        width: 100%;
}
.award-section .ns-contr .award .award-list[data-v-1cd637a8]{
        width: 100%;
}
.award-section .ns-contr .award .award-list img.ml[data-v-1cd637a8]{
        width: 100%;
}
}