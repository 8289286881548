.packages[data-v-0e488d31] {
    padding-top: 50px;
    padding-bottom: 50px;
}
.packages .medicine .owl-stage-outer[data-v-0e488d31] {
    overflow-y: visible;
    padding-bottom: 50px;
}
.packages .medicine .item-med[data-v-0e488d31] {
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    padding-bottom: 30px;
}
.packages .medicine .item-med h2[data-v-0e488d31] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 15px 0px;
    padding: 0px 15px;
    color: #E6771B;
}
.packages .medicine .item-med img[data-v-0e488d31] {
    width: 100%;
}
.packages .medicine .item-med .content[data-v-0e488d31] {
    display: inline-flex;
    width: 100%;
    padding: 0px 15px;
    padding-bottom: 65px;
}
.packages .medicine .item-med .content img[data-v-0e488d31] {
    width: auto !important;
    margin-right: 10px;
}
.packages .medicine .item-med .book-now[data-v-0e488d31] { 
    background: #E6771B;
    border-radius: 50px;
    border: none;
    color: #fff;
    padding: 8px 20px;
    font-size: 18px;
    height: 45px;
    width: 80%;
    left: 10%;
    position: absolute;
    bottom: -25px;
}
.packages .works-contr[data-v-0e488d31] {
    position: relative;
}
.packages .works-contr[data-v-0e488d31]::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 2px;
    width: 75%;
    border-top: 2px dashed #000;
    top: 60px;
}
.work img[data-v-0e488d31] {
    width: 120px;
}
.packages .works-contr .work[data-v-0e488d31] {
    position: relative;
}
.packages .works-contr .work h1[data-v-0e488d31] {
    font-size: 21px;
    color: #058ED2;
    margin: 10px 0px;
}
.packages .top-brand[data-v-0e488d31] {
    background-color: #058ED2;
    border-radius: 10px;
    padding: 60px 100px;
}
.packages .top-brand img[data-v-0e488d31] {
    width: auto;
    border-radius: 10px;
    max-width: 100%}
.packages .top-brand .owl-nav .owl-prev[data-v-0e488d31] {
    left: 25px;
}
.packages .top-brand .owl-nav .owl-prev span[data-v-0e488d31] {
    background-image: url(../../images/caro-pre-icon-w.svg);
}
.packages .top-brand .owl-nav .owl-next[data-v-0e488d31] {
    right: 25px;
}
.packages .top-brand .owl-nav .owl-next span[data-v-0e488d31] {
    background-image: url(../../images/caro-nxt-icon-w.svg);
}
.packages .best-package[data-v-0e488d31] {
    border-radius: 15px;
    margin-top: 40px;
    overflow: hidden;
}
.tl-contr[data-v-0e488d31] {
    display: inline-block;
}
.tl-contr h1[data-v-0e488d31] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-0e488d31]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.tl-contr h2.ssd[data-v-0e488d31] {
    position: relative;
    padding-top: 15px;
    margin-bottom: 30px;
    font-size: 28px;
}
.tl-contr h2.ssd[data-v-0e488d31]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.tl-contr label[data-v-0e488d31] {
    padding-top: 6px;
}
.tl-contr ul.country-contr[data-v-0e488d31] {
    float: right;
    margin-bottom: 5px;
}
.tl-contr ul.country-contr li[data-v-0e488d31] {
    float: left;
    border-bottom: 6px solid #F1F3F5;
    padding-left: 20px;
}
.tl-contr ul.country-contr li select[data-v-0e488d31] {
    border: 1px solid #E6771B;
    border-radius: 10px;
    padding: 5px 15px;
}
.m-100[data-v-0e488d31] {
    margin-top: 50px;
}
.packages .medicine .item-med .content[data-v-0e488d31] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 75px;
}
@media all and (max-width: 1440px) {
.work img[data-v-0e488d31] {
        width: 100px;
}
.packages .works-contr[data-v-0e488d31]::before{
        top: 45px;
}
}
@media all and (max-width: 991px) {
.work img[data-v-0e488d31] {
        width: 80px;
}
.packages .works-contr[data-v-0e488d31]::before {
        top: 40px;
}
.packages .works-contr .work h1[data-v-0e488d31] {
        font-size: 18px;
}
.packages .top-brand[data-v-0e488d31] {
        padding: 20px 20px;
}
.tl-contr h2.ssd[data-v-0e488d31]{
        font-size: 24px;
}
}
@media all and (max-width: 767px) {
.m-list[data-v-0e488d31] {
        width: 50%;
        margin-bottom: 50px;
}
.mn-top2[data-v-0e488d31] {
        margin-top: 30px !important;
}
.packages .medicine .item-med .book-now[data-v-0e488d31]{
        font-size: 16px;
}
.w-list[data-v-0e488d31] {
        width: 50%;
        margin-top: 20px;
}
.packages .works-contr[data-v-0e488d31]::before{
        display: none;
}
.tl-contr h2.ssd[data-v-0e488d31]{
        margin-bottom: 20px;
}
.packages .medicine .item-med .book-now[data-v-0e488d31]{
        bottom: -20px;
}
.packages[data-v-0e488d31]{
        padding-top: 30px;
        padding-bottom: 30px;
}
.m-100[data-v-0e488d31] {
        margin-top: 30px;
}
.packages .best-package[data-v-0e488d31]{
        margin-top:30px;
}
}
@media all and (max-width: 420px) {
.m-list[data-v-0e488d31] {
        width: 100%;
}
}