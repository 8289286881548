/*first section open*/
.tsc-contr .l-one[data-v-657e2e88] {
    float: left;
    width: 16.666%;
    text-align: start;
    padding: 30px 0px;
}
.l-one h3[data-v-657e2e88] {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
}

/*first section close*/
 

/*doctors listing open*/
.tl-contr[data-v-657e2e88] {
    display: inline-block;
}
.tl-contr h1[data-v-657e2e88] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-657e2e88]::before {
    content: "";
    background: #e6771b;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.tl-contr label[data-v-657e2e88] {
    padding-top: 6px;
}
.tl-contr ul.country-contr[data-v-657e2e88] {
    float: right;
    margin-bottom: 5px;
}
.tl-contr ul.country-contr li[data-v-657e2e88] {
    float: left;
    padding-left: 20px;
}
.tl-contr ul.country-contr li select[data-v-657e2e88] {
    border: 1px solid #e6771b;
    border-radius: 10px;
    padding: 5px 15px;
    width: 100%;
}
.doctors[data-v-657e2e88]{
    background: #F1F3F5;
    padding-bottom: 50px;
    padding-top: 50px;
}
.doctors-list[data-v-657e2e88] {
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    padding-left: 195px;
    min-height: 223px;
    margin-bottom: 25px;
}
.doctors-list a[data-v-657e2e88] {
    color: #213B4D;
}
.doctor-img[data-v-657e2e88] {
    position: absolute;
    left: 0px;
    width: 180px;
    bottom: 0;
}
.doctor-img img[data-v-657e2e88] {
    max-width: 100%;
}
.hs-logo[data-v-657e2e88] {
    position: absolute;
    right: 20px;
    top: 10px;
}
.ln-contr[data-v-657e2e88] {
    float: left;
    width: 100%;
}
.ln-contr p[data-v-657e2e88] {
    float: left;
    width: 100%;
}
.ln-contr img[data-v-657e2e88] {
    margin-right: 5px;
}
.ln-contr span.colr1[data-v-657e2e88]{
    color:#058ED2;
}
.doctors-list p[data-v-657e2e88] {
    margin-bottom: 3px;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 26px;
    padding-right: 15px;
}
.exp-contr[data-v-657e2e88] {
    background: #058ED2;
    border-radius: 5px;
    color: #fff;
    width: -webkit-max-content;
    width: max-content;
    padding: 3px 15px;
    margin-bottom: 8px;
}
.doctors-list h2[data-v-657e2e88] {
    padding-top: 15px;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 48px;
    margin-bottom: 0px;
    padding-right: 80px;
}
.btcn[data-v-657e2e88] {
    background: #F1F3F5;
    border-radius: 5px;
    color: #213B4D;
    height: 40px;
    width: 150px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.btcn1[data-v-657e2e88] {
    background: #E6771B;
    border-radius: 5px;
    color: #ffffff !important;
    height: 40px;
    width: 150px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
}
.btcn[data-v-657e2e88]:hover {
    background-color: #dcdddf;
    color: #213B4D;
}
.btcn1[data-v-657e2e88]:hover {
    background-color: #cf660f;
    color: #ffffff;
}

/*doctors listing close*/





/*contact now open*/
.best-package[data-v-657e2e88] {
    background: #e6771b;
    color: #fff;
    border-radius: 15px;
    padding: 60px 90px;
    position: relative;
    padding-right: 280px;
    margin-top: 30px;
}
.best-package h2[data-v-657e2e88] {
    font-size: 54px;
}
.best-package p[data-v-657e2e88] {
    font-size: 24px;
}
.best-package .contact[data-v-657e2e88] {
    background: #ffffff;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    color: #213b4d;
    line-height: 50px;
    position: absolute;
    right: 60px;
    top: 60px;
    font-size: 24px;
}

/*contact now close*/
@media all and (max-width: 1440px) {
.doctors[data-v-657e2e88] {
        padding-bottom: 50px;
        padding-top: 50px;
}
.doctors-list h2[data-v-657e2e88]{
        height: 41px;
}
.tl-contr[data-v-657e2e88]{
        margin-bottom: 0;
}
.best-package[data-v-657e2e88]{
        padding: 40px 40px;
}
}
@media all and (max-width: 1199px) {
.btcn[data-v-657e2e88]{
        height: 38px;
        width: 105px;
}
.btcn1[data-v-657e2e88]{
        height: 38px;
        width: 105px;
}
}
@media all and (max-width: 991px) {
.doctor-img[data-v-657e2e88]{
        position: static;
        position: initial;
}
.doctors-list[data-v-657e2e88]{
        padding-left: 15px;
}
.doctors-list h2[data-v-657e2e88]{
        padding-right: 15px;
}
.l-one h3[data-v-657e2e88]{
        font-size: 18px;
}
}
@media all and (max-width: 767px) {
.btcn1[data-v-657e2e88] {
        margin-left: 0;
        display: table-caption;
        margin-top: 0;
}
}
@media all and (max-width: 599px) {
.row.col-12.doctors.main-pd[data-v-657e2e88] {
        padding-right: 0;
        padding-left: 0;
}
}
@media all and (max-width: 480px) {
.dcter-list[data-v-657e2e88]{
        width: 100%;
}
.tl-contr ul.country-contr[data-v-657e2e88]{
        width: 80%;
}
.tl-contr ul.country-contr li[data-v-657e2e88]{
        width: 100%;
        margin-bottom: 5px;
}
}
.doc-img[data-v-657e2e88]{
    object-fit:fill !important;
}
.dep-img[data-v-657e2e88] {
    border-radius: 500px;
    cursor: pointer;
    margin: auto;
}
.dep-img-active[data-v-657e2e88] {
    border-radius: 500px;
    cursor: pointer;
    border-color: #E6771B;
    border-width: medium;
}