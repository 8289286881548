.blog-section .nav-tabs[data-v-145cbf5a] {
    border: 1px solid #BCBEC0;
    box-sizing: border-box;
    border-radius: 10px;
    height: 65px;
    padding: 0px 20px;
    margin-top: 40px;
}
.blog-section .nav-tabs .nav-link[data-v-145cbf5a] {
    padding: 0;
    height: 35px;
    margin-top: 13px;
    line-height: 33px;
    color: #213B4D;
    border-radius: 5px;
}
.blog-section .nav-tabs .nav-link[data-v-145cbf5a]:hover {
    background: #E6771B;
    border-radius: 5px;
    color: #fff;
}
.blog-section .nav-tabs .nav-link.active[data-v-145cbf5a] {
    background: #E6771B;
    color: #fff;
    border: none;
}
.blog-section .blog-list[data-v-145cbf5a] {
    margin-top: 30px;
}
.blog-section .blog-list img[data-v-145cbf5a] {
    border-radius: 10px;
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.blog-section .blog-list h2[data-v-145cbf5a] {
    height: 25px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 12px;
    font-family: WorkSans-Medium;
    font-weight: 600;
}
.blog-section .blog-list .content[data-v-145cbf5a] {
    height: 70px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: WorkSans-Medium;
    font-weight: 400;
}
.blog-section .blog-list a[data-v-145cbf5a] {
    font-family: WorkSans-Medium;
    font-weight: 500;
    color: #213B4D;
    margin-top: 10px;
    float: left;
}
.blog-section .blog-list a span[data-v-145cbf5a] {
    border-bottom: 1px solid #213B4D;
}
section.blog-section[data-v-145cbf5a] {
    overflow-x: visible;
    overflow-x: initial;
}
@media all and (max-width: 1440px) {
.blog-section .blog-list img[data-v-145cbf5a]{
        height: 300px;
}
.blog-section .blog-list[data-v-145cbf5a]{
        margin-top: 30px;
}
}
@media all and (max-width: 1199px) {
.blog-section .blog-list img[data-v-145cbf5a]{
        height: 250px;
}
}
@media all and (max-width: 991px) {
.blog-section .blog-list[data-v-145cbf5a] {
        margin-top: 30px;
}
#nav-tabContent[data-v-145cbf5a] {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
}
}
@media all and (max-width: 767px) {
.blog-listing[data-v-145cbf5a]{
        width: 50%;
}
.blog-section .nav-tabs .nav-link[data-v-145cbf5a]{
        color: #fff;
        background: #e6771b;
        margin: 13px 5px;
}
.blog-section .nav-tabs[data-v-145cbf5a] {
        padding: 0px 5px;
        height: auto;
}
}
@media all and (max-width: 575px) {
#nav-tabContent[data-v-145cbf5a]{
        padding-left: 0!important;
        padding-right: 0!important;
}
}
@media all and (max-width: 480px) {
.blog-listing[data-v-145cbf5a] {
        width: 100%;
}
.blog-section .blog-list img[data-v-145cbf5a]{
        width: 100%;
}
}
