.tl-contr[data-v-69d29389] {
    margin-bottom: 30px;
    display: inline-block
}
.tl-contr h1[data-v-69d29389] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px
}
.tl-contr h1[data-v-69d29389]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0
}
.packages[data-v-69d29389] {
    padding: 50px 20px;
    background: #F1F3F5;
    padding-bottom: 30px;
    margin: 0
}
.packages .item-list[data-v-69d29389] {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative
}
.packages .item-list img[data-v-69d29389] {
    width: 100%
}
.packages .item-list h2[data-v-69d29389] {
    text-align: left;
    padding: 20px;
    margin-bottom: 0
}
.packages .item-list .benefit[data-v-69d29389] {
    padding: 20px;
    background: #d0eaf7;
    position: relative
}
.packages .item-list .benefit h2[data-v-69d29389] {
    padding: 0;
    padding-bottom: 15px
}
.packages .item-list .benefit ul[data-v-69d29389] {
    margin: 0
}
.packages .item-list .benefit ul li[data-v-69d29389] {
    text-align: left;
    position: relative;
    padding-left: 30px;
    line-height: 28px
}
.packages .item-list .benefit ul li[data-v-69d29389]::before {
    background-image: url(../../images/star-icon.svg);
    background-size: contain;
    position: absolute;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    top: 5px
}
.packages .item-list .benefit .view[data-v-69d29389] {
    background: #058ED2;
    width: auto;
    float: right;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: TitilliumWeb-SemiBold
}
.packages .item-list .hospital[data-v-69d29389] {
    width: 230px;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 208px;
    background: #fff;
    -webkit-clip-path: polygon(0 0, 100% 0, 29% 72%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 29% 72%, 0 100%);
    padding-right: 110px;
    border-radius: 10px 0px
}
.packages .item-list .hospital .c-one[data-v-69d29389] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 21px;
    font-family: TitilliumWeb-SemiBold
}
.packages .item-list .hospital img[data-v-69d29389] {
    width: 30px !important;
    margin-bottom: 10px
}
.packages .item-list .botm-content[data-v-69d29389] {
    padding: 20px;
    padding-bottom: 25px
}
.packages .item-list .botm-content p[data-v-69d29389] {
    text-align: left;
    float: left;
    width: 50%;
    font-size: 21px
}
.packages .item-list .botm-content p span[data-v-69d29389] {
    font-family: TitilliumWeb-SemiBold
}
.packages .item-list .botm-content .color[data-v-69d29389] {
    color: #E6771B
}
.packages .item-list .botm-content .color span[data-v-69d29389] {
    font-size: 16px;
    font-family: TitilliumWeb-Regular
}
.packages .item-list .botm-content .book-now[data-v-69d29389] {
    height: 50px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 24px;
    font-family: TitilliumWeb-SemiBold
}
.best-doctor-contr[data-v-69d29389] {
    padding-top: 100px;
    padding-bottom: 100px
}
.best-doctor-contr img[data-v-69d29389] {
    border: 30px solid #F1F3F5;
    border-radius: 100%;
    width: auto !important;
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px
}
.best-doctor-contr h2.inline[data-v-69d29389] {
    display: inline-flex;
    margin-top: 10px
}
.best-doctor-contr h2.inline img[data-v-69d29389] {
    border: none;
    border-radius: 0;
    margin-right: 15px;
    margin-bottom: 0
}
.Excellence[data-v-69d29389] {
    background: #F1F3F5;
    position: relative;
    padding-left: 320px;
    min-height: 300px;
    padding-top: 25px;
    margin-top: 50px
}
.Excellence img[data-v-69d29389] {
    position: absolute;
    left: 25px;
    top: 25px;
    border: 35px solid #fff;
    border-radius: 100%
}
.Excellence h2[data-v-69d29389] {
    padding-bottom: 15px
}
.Excellence ul li[data-v-69d29389] {
    position: relative;
    line-height: 32px;
    padding-left: 25px
}
.Excellence ul li[data-v-69d29389]::before {
    width: 14px;
    height: 14px;
    content: "";
    background: #C4C4C4;
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 8px
}
.right-sec[data-v-69d29389] {
    padding-left: 60px
}
.right-sec .form-contr[data-v-69d29389] {
    background: #E6771B;
    color: #fff;
    margin-top: 50px;
    padding: 50px 30px
}
.right-sec .form-contr .form[data-v-69d29389] {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 0px 15px;
    background: #fff;
    color: #213B4D;
    position: relative
}
.right-sec .form-contr .mnt[data-v-69d29389] {
    margin-top: 15px
}
.right-sec .form-contr textarea[data-v-69d29389] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px
}
.right-sec .form-contr .inputfile[data-v-69d29389] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}
.right-sec .form-contr span[data-v-69d29389] {
    line-height: 50px
}
.right-sec .form-contr .inputfile+label[data-v-69d29389] {
    cursor: pointer
}
.right-sec .form-contr .no-js .inputfile+label[data-v-69d29389] {
    display: none
}
.right-sec .form-contr .inputfile:focus+label[data-v-69d29389], .right-sec .form-contr .inputfile.has-focus+label[data-v-69d29389] {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px
}
.right-sec .form-contr .inputfile+label svg[data-v-69d29389] {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    position: absolute;
    right: 15px;
    top: 15px
}
.right-sec .recent-blog[data-v-69d29389] {
    background: #EDF2F7;
    border-radius: 10px;
    padding: 40px 30px;
    margin-top: 80px
}
.right-sec .recent-blog h2[data-v-69d29389] {
    margin-bottom: 25px
}
.right-sec .recent-blog .blog-list[data-v-69d29389] {
    position: relative;
    padding-left: 88px;
    min-height: 90px
}
.right-sec .recent-blog .blog-list img[data-v-69d29389] {
    max-width: 68px;
    position: absolute;
    left: 0
}
.right-sec .recent-blog .blog-list h2[data-v-69d29389] {
    font-size: 18px;
    margin-bottom: 8px
}
.right-sec .recent-blog .blog-list .content[data-v-69d29389] {
    font-size: 14px;
    line-height: 20px
}
@media all and (max-width: 1600px) {
.main-pd[data-v-69d29389] {
        padding: 0px 100px
}
}